/* eslint-disable no-underscore-dangle */
import { Environment } from '../enums/environment';

export type IntercomSetting = {
  disableIntercom: boolean;
};

export type Sidecar = {
  __sidecarFormatVersion: number;
  applicationId: string;
  aadApplicationId: string;
  applicationName: string;
  appsApiBaseUrl: string;
  cdfApiBaseUrl: string;
  cdfCluster: string;
  docsSiteBaseUrl: string;
  intercom: string;
  nomaApiBaseUrl: string;
  isCurrentStaticVersion: boolean;
  intercomSettings: IntercomSetting;
  disableLegacyLogin: boolean;
  environment: string; // new property to hold deployed env
};

export const getEnvironment = (): Environment => {
  const { hostname } = window.location;
  if (
    hostname.includes('pr-') ||
    hostname.includes('.staging.') ||
    hostname.includes('localhost')
  ) {
    return Environment.DEVELOPMENT;
  }

  if (hostname.includes('.preview')) {
    return Environment.PREVIEW;
  }

  return Environment.PRODUCTION;
};

const generateBaseUrls = (cluster: string, prod = false) => {
  switch (cluster) {
    case 'ew1': {
      return {
        appsApiBaseUrl: 'https://apps-api.staging.cognite.ai',
        cdfApiBaseUrl: 'https://api.cognitedata.com',
        cdfCluster: '',
      };
    }
    default: {
      return {
        appsApiBaseUrl: prod
          ? `https://apps-api.${cluster}.cognite.ai`
          : `https://apps-api.staging.${cluster}.cognite.ai`,
        cdfApiBaseUrl: `https://${cluster}.cognitedata.com`,
        cdfCluster: cluster,
      };
    }
  }
};

const getAADId = (deployedEnv: Environment): { aadApplicationId?: string } => {
  if (deployedEnv === Environment.PRODUCTION) {
    return {
      aadApplicationId: '67dff84c-75ed-46e0-819c-ae0af2db342a',
    };
  }
  return {
    aadApplicationId: 'c82b4fe4-172f-463a-ae49-3831c6ad6946',
  };
};

let CLUSTER = 'ew1';
/**
 * @param cluster optional param to generate sidecar relevant to env
 * @returns {Sidecar} Object
 */
export const getSidecarForCluster = (cluster?: string | null) => {
  CLUSTER = cluster || 'ew1';

  const deployedEnv = getEnvironment();
  const isPROD = deployedEnv === Environment.PRODUCTION;
  const sidecar: Sidecar = {
    ...((window as any).__cogniteSidecar || {}),
    ...generateBaseUrls(CLUSTER, isPROD),
    __sidecarFormatVersion: 1,
    applicationId: 'cognite-remote',
    applicationName: 'Cognite Remote',
    docsSiteBaseUrl: 'https://docs.cognite.com',
    intercomSettings: {
      disableIntercom: true,
    },
    disableLegacyLogin: false,
    environment: deployedEnv,
    ...getAADId(deployedEnv),
  };

  return sidecar;
};
