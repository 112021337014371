import React, { useState } from 'react';
import { Icon, Tooltip } from '@cognite/cogs.js';
import { COPYING_LOADER_TIMEOUT } from '../../../constants';

interface Props {
  text: string;
}
export const CopyButton: React.FC<Props> = ({ text }: Props) => {
  const [isCopying, setCopying] = useState(false);

  const copyToClipboard = (text: string) => {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    const parentElement = document.getElementById('root');

    if (parentElement) {
      parentElement.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      parentElement.removeChild(textField);
    }
  };

  const handleClick = () => {
    setCopying(true);
    copyToClipboard(text);

    setTimeout(() => {
      setCopying(false);
    }, COPYING_LOADER_TIMEOUT);
  };

  return (
    <Tooltip content={<span>{isCopying ? 'Copied' : 'Copy'}</span>}>
      <Icon type={isCopying ? 'Checkmark' : 'Copy'} onClick={handleClick} />
    </Tooltip>
  );
};
