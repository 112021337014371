import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import omit from 'lodash/omit';

/**
 * @param name querying search param name
 * @param pushState should the new route be pushed or replaced
 * @returns
 */
export const useSearchParam = (
  name: string,
  pushState = true
): [string | undefined, (s?: string) => void] => {
  const history = useHistory();
  const { location } = history;
  const search = qs.parse(location.search);
  let val = search[name];

  if (val && Array.isArray(val)) {
    [val] = val;
  }

  const setSearchParam = (newVal?: string) => {
    const newSearch = newVal
      ? {
          ...search,
          [name]: newVal,
        }
      : omit(search, name);

    history[pushState ? 'push' : 'replace']({
      pathname: location.pathname,
      search: qs.stringify(newSearch),
      hash: location.hash,
      state: location.state,
    });
  };
  return [val ? decodeURIComponent(val) : undefined, setSearchParam];
};
