import React from 'react';
import { Input, Modal, Title } from '@cognite/cogs.js';
import { LOCALIZATION } from '../../constants';

interface Props {
  onOk: () => any;
  onCancel: () => any;
  onChange: (v: string) => any;
}

export const AddNewConfigModal: React.FC<Props> = ({
  onOk,
  onCancel,
  onChange,
}: Props) => (
  <Modal visible okText="Create config" onOk={onOk} onCancel={onCancel}>
    <h4>
      <b>{LOCALIZATION.CREATE_NEW_TWIN}</b>
    </h4>
    <Title level={2}>{LOCALIZATION.GET_START}</Title>
    <br />
    <p>{LOCALIZATION.TWIN_DESC}</p>
    <div>
      <div>Twin name</div>
      <Input variant="default" onChange={(e) => onChange(e.target.value)} />
    </div>
  </Modal>
);
