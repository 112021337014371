import JSONEditor, {
  AutoCompleteElementType,
  JSONEditorOptions,
  JSONPath,
  MenuItem,
  MenuItemNode,
  ParseError,
  SchemaValidationError,
  ValidationError,
} from 'jsoneditor';
import { showToast } from 'userInterface/components/showToast/showToast';
import { ToastModes } from 'userInterface/util/enums/ToastModes';
import {
  CogniteOpenApiSchemaValidator,
  ArrayNode,
  StringNode,
  MapNode,
  BaseNode,
  BaseNodes,
  DataType,
  ErrorType,
  getJson,
} from '../validator';
import { JsonEditorInstanceWrapper } from './JsonEditorInstanceWrapper';
import { ID_SEPRATOR, LOCALIZATION } from '../constants';

const extractField = (key: string) => key.split(':')[1];

export class CogniteJsonEditorOptions implements JSONEditorOptions {
  private validator: CogniteOpenApiSchemaValidator;
  public onChangeText: (text: string) => void;
  public onEditorError: (errorMap: Map<string, string[]>) => void;
  public templates = [];
  public autocomplete = {};
  public editorErrors: Map<string, string[]> = new Map();

  constructor(
    cogValidator: CogniteOpenApiSchemaValidator,
    onchange: (text: string) => void,
    onerror: (errorMap: Map<string, string[]>) => void
  ) {
    this.validator = cogValidator;
    this.onChangeText = onchange;
    this.onEditorError = onerror;
    this.templates = this.generateTemplates();
    this.autocomplete = this.generateAutocompleteOptions();
  }

  public mode: 'tree' | 'code' | 'preview' | undefined = 'tree';

  // public modes: JSONEditorMode[] = ['tree', 'code'];

  public limitDragging = true;

  public enableSort = false;
  public enableTransform = false;

  public indentation = 4;
  public escapeUnicode = true;
  public timestampTag = false;
  public search = true;

  /**
   * Create and return all possible templates for inserting
   */
  public generateTemplates(): any {
    const allTemplates: any = [];

    // Here we handle all the add possibilities for each node
    this.validator.schemaNodes().forEach((ele) => {
      const key = extractField(ele.key);
      let template;

      // Handle: Add as a property of object
      template = {
        text: key,
        title: `${key}-${ele.node.description}`,
        className: 'jsoneditor-type-object',
        field: key,
        value: ele.data,
      };
      allTemplates.push(template);

      // Handle: Add as an association type
      if (ele.node.discriminator && ele.node.data) {
        // If discriminator exists, add all sub types as templates
        Object.entries(ele.node.data).forEach(([subKey, subVal]) => {
          template = {
            text: `${key}-${subKey}`,
            title: `${key}-${subKey}-${subVal.description}`,
            className: 'jsoneditor-type-object',
            field: `${key}`,
            value: getJson(subVal as BaseNode),
          };
          allTemplates.push(template);
        });
      }

      // Handle: Add as sample object for array/map
      if (ele.node instanceof ArrayNode || ele.node instanceof MapNode) {
        // Handle: if sample object is associationType
        if (ele.node.sampleData && ele.node.sampleData.discriminator) {
          // If discriminator exists, add all sub types as templates
          Object.entries(ele.node.sampleData.data as BaseNodes).forEach(
            ([subKey, subVal]) => {
              template = {
                text: `${key}-${subKey}`,
                title: `${key}-${subKey}-${subVal.description}`,
                className: 'jsoneditor-type-object',
                field: `${key}-sample`,
                value: getJson(subVal as BaseNode),
              };
              allTemplates.push(template);
            }
          );
          // Handle: add as a direct sample object
        } else {
          template = {
            text: `${key}-sample`,
            title: `${key}-sample-${ele.node.description}`,
            className: 'jsoneditor-type-object',
            field: `${key}-sample`,
            value: ele.sample,
          };
          allTemplates.push(template);
        }
      }
    });

    return allTemplates;
  }

  public onCreateMenu = (
    menuItemList: MenuItem[],
    node: MenuItemNode
  ): any[] => {
    let menuItems = menuItemList;
    const { currentJson } = JsonEditorInstanceWrapper;
    const { path } = node;
    // get parent Path for add function
    const parentPath: string[] = [...path];
    // Skip case: adding first child
    if (node.type !== 'append') {
      parentPath.pop();
    }

    const removePossibility = this.validator.removeJsonNode(currentJson, [
      ...path,
    ]);

    // Creating a new MenuItem array that only contains valid items
    // and replace submenu with valid items
    menuItems.forEach((itm) => {
      const item = itm;
      if (item.text === 'Insert') {
        item.click = undefined;
        item.submenu = this.createValidInsertMenu(
          item.submenu,
          currentJson,
          parentPath
        );
        item.className = 'json-editor-insert-btn';
      }
      // adding same logic to Append
      else if (node.type === 'append' && item.text === 'Append') {
        item.text = 'Insert';
        item.click = undefined;
        item.submenu = this.createValidInsertMenu(
          item.submenu,
          currentJson,
          parentPath
        );
      }
      // if removeNode validation returns error
      // Remove default Remove(Delete) function and alert the error
      // except for ErrorType.InvalidPath
      else if (item.text === 'Remove') {
        item.title = LOCALIZATION.REMOVE_ENABLED;
        const errors = this.editorErrors;
        const pathString = `.${path.join('.')}`;

        if (!errors.has(pathString)) {
          // allow remove if validation errors are available
          if (removePossibility.error) {
            // allows Remove even it has InvalidPath error
            if (removePossibility.error.type === ErrorType.InvalidPath) {
              item.title = LOCALIZATION.REMOVE_INVALID_PATH;
            } else {
              item.className = 'warning-triangle';
              switch (removePossibility.error.type) {
                case ErrorType.RequiredNode:
                  item.title = LOCALIZATION.REMOVE_MANDATORY;
                  item.click = () => {
                    showToast(ToastModes.error, LOCALIZATION.REMOVE_MANDATORY);
                  };
                  break;
                case ErrorType.MinLength:
                  item.title = LOCALIZATION.REMOVE_MINIMUM_LENGTH;
                  item.click = () => {
                    showToast(
                      ToastModes.error,
                      LOCALIZATION.REMOVE_MINIMUM_LENGTH
                    );
                  };
                  break;
                default:
                  item.title = LOCALIZATION.REMOVE_DISABLED;
                  item.click = () => {
                    showToast(ToastModes.error, LOCALIZATION.REMOVE_DISABLED);
                  };
                  break;
              }
            }
          }
        }
      }
    });

    // remove unwanted menu items
    menuItems = menuItems.filter(
      (item) =>
        item.text !== 'Type' &&
        item.text !== 'Sort' &&
        item.text !== 'Transform' &&
        item.text !== 'Extract' &&
        item.text !== 'Duplicate' &&
        item.text !== 'Append' &&
        item.type !== 'separator'
    );

    return menuItems;
  };

  public generateAutocompleteOptions(): any {
    return {
      filter: 'start',
      trigger: 'focus',
      getOptions: (
        _text: string,
        path: JSONPath,
        _input: AutoCompleteElementType,
        editor: JSONEditor
      ) =>
        new Promise((resolve, reject) => {
          const rootJson = JSON.parse(editor.getText());
          const resultNode = this.validator.schemaNode([...path], rootJson);

          if (resultNode && resultNode.type === DataType.string) {
            const stringNode = resultNode as StringNode;
            if (
              stringNode.possibleValues &&
              stringNode.possibleValues.length > 0
            ) {
              resolve(stringNode.possibleValues);
            } else {
              reject();
            }
          }
        }),
    };
  }

  public onError = (err: any): void => {
    if (err) {
      showToast(ToastModes.error, `Validation Error! : ${err.message}`);
    }
  };

  public onValidate = (
    json: any
  ): ValidationError[] | Promise<ValidationError[]> =>
    this.validator.validate(json);

  public onValidationError = (
    errors: ReadonlyArray<SchemaValidationError | ParseError>
  ): void => {
    const errorMap = new Map<string, string[]>();
    errors.forEach((val: any) => {
      let key = '';
      let value = '';

      if (val.error) {
        // for errors in tree mode
        key = `.${val.error.path.join('.')}`;
        value = val.error.message;
      }
      if (val.dataPath) {
        // for errors in code mode
        key = val.dataPath;
        value = val.message;
      }

      if (key && value) {
        let valueArr = errorMap.get(key);
        if (!valueArr) {
          valueArr = [];
          errorMap.set(key, valueArr);
        }
        valueArr.push(value);
      }
    });
    this.editorErrors = errorMap;
    this.onEditorError(errorMap);
  };

  private createValidInsertMenu = (
    submenu: MenuItem[] | undefined,
    currentJson: any,
    parentPath: (string | number)[]
  ): any => {
    const { resultNode, error } = this.validator.schemaNodeWithError(
      [...parentPath],
      currentJson
    );

    if (error) {
      showToast(ToastModes.error, LOCALIZATION.INCONSISTENT_VALUE);
      return undefined;
    }

    const validInsertItems: any = this.getValidInsertItems(
      parentPath,
      currentJson,
      resultNode
    );
    const existingKeys: (number | string)[] = Object.keys(
      this.getPathObject(currentJson, [...parentPath])
    );

    if (submenu === undefined || submenu.length === 0) {
      return undefined;
    }

    const validMenuItems: MenuItem[] = [];

    submenu?.forEach((subItem) => {
      const item = subItem;
      if (validInsertItems !== undefined && validInsertItems.length !== 0) {
        Object.keys(validInsertItems).forEach((key: any) => {
          if (
            item.text === key &&
            item.title === validInsertItems[key].description &&
            // Also we have to match the keys as a whole
            // For discriminator types, if any key is added with base type, it needs to be filtered out.
            !existingKeys.includes(key) &&
            !existingKeys.includes(key.split('-')[0])
          ) {
            // Remove unique id from the title
            const temp = item.title.split(ID_SEPRATOR)[0];
            item.title = temp;

            validMenuItems.push(item);
            existingKeys.push(key);
          }
        });
      }
    });

    return validMenuItems;
  };

  private getPathObject = (json: any, path: (number | string)[]): any => {
    let subTree = json;
    path.forEach((step: number | string) => {
      subTree = subTree[step];
    });
    return subTree;
  };

  private getValidInsertItems = (
    parentPath: (string | number)[],
    currentJson: any,
    node: BaseNode | undefined | null
  ) => {
    const key = parentPath[parentPath.length - 1];
    let resultNode = node;

    /**
     * If dicriminator(parent), resultNode should get from data[`type`]
     */
    if (resultNode?.discriminator) {
      const currentData = this.getPathObject(currentJson, parentPath);
      const typeIndicatorKey = resultNode.discriminator.propertyName;
      const dataType = currentData[typeIndicatorKey];
      resultNode = (resultNode.data as BaseNodes)[dataType];
    }

    /**
     * When adding items to an Array or a Map,
     * returning a IData object with matching key and description
     */
    if (resultNode instanceof ArrayNode || resultNode instanceof MapNode) {
      if (resultNode.sampleData?.discriminator) {
        // Handle: Association comes with array/map
        const res: any = {};
        this.replaceKeyWithDiscriminatorTypes(
          res,
          resultNode.sampleData,
          `${key}`
        );
        return res;
      }
      // Handle: Sample data for array/map
      const ret = {
        [`${key}-sample`]: {
          description: `${key}-sample-${resultNode.description}`,
        },
      };
      return ret;

      // Handle: Add as property of object
    }
    if (resultNode?.data) {
      // Since some nodes might be deleted by the logic below, this object must be cloned.
      const res: any = {};
      Object.entries(resultNode.data).forEach(([k, n]) => {
        res[k] = {
          description: `${k}-${n.description}`,
          discriminator: n.discriminator,
          data: n.data,
        };
      });

      // Handle: Add as property of association type
      Object.entries(res as Record<string, unknown>).forEach(([k, subNode]) => {
        // if they are descriminator types as data then replace insert items as `type-discriminatorType`
        if ((subNode as BaseNode).discriminator) {
          // If discriminator available, then node is a BaseNode
          this.replaceKeyWithDiscriminatorTypes(res, subNode as BaseNode, k);
        }
      });
      return res;
    }
    showToast(ToastModes.error, LOCALIZATION.INCONSISTENT_VALUE);
    return undefined;
  };

  private replaceKeyWithDiscriminatorTypes = (
    res: any,
    node: BaseNode,
    key: string
  ) => {
    delete res[key];
    Object.entries((node as BaseNode).data as Record<string, unknown>).forEach(
      ([subKey, val]) => {
        res[`${key}-${subKey}`] = {
          description: `${key}-${subKey}-${(val as BaseNode).description}`,
        };
      }
    );
  };
}
