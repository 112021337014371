import { showToast } from 'userInterface/components/showToast/showToast';
import { ToastModes } from 'userInterface/util/enums/ToastModes';
import { Metrics } from '@cognite/metrics';
import { Api } from './Api';
import { DigitalTwinApi } from './DigitalTwinApi';
import { LOCALIZATION, USE_LOCAL_FILES_AND_NO_LOGIN } from '../constants';
import localJsonFile from '../config/MauiA.json';
import { JsonConfig, JsonPayLoad } from '../userInterface/util/types';
import { JsonEditorInstanceWrapper } from './JsonEditorInstanceWrapper';
import { Utils } from './Utils';
import { MetricsEvents } from '../userInterface/util/enums/MetricsEvents';

export class ApiManager {
  private static apiInstance: Api;
  private static metrics = Metrics.create('ApiManager');

  public static get api(): Api {
    if (!ApiManager.apiInstance) {
      ApiManager.apiInstance = new DigitalTwinApi();
    }
    return ApiManager.apiInstance;
  }

  public static loadJsonConfigs = async (): Promise<any> => {
    let jsonConfigs;
    try {
      jsonConfigs = await ApiManager.api.jsonList();
    } catch (error: any) {
      showToast(
        ToastModes.error,
        Utils.replaceString(
          LOCALIZATION.RETRIEVE_CONFIGS_FAIL,
          Utils.extractErrorMessage(error)
        )
      );
      if (USE_LOCAL_FILES_AND_NO_LOGIN) {
        jsonConfigs = new Map().set(123, { id: 123, data: localJsonFile });
      }
    }
    return jsonConfigs;
  };

  public static onSaveAs = async (): Promise<JsonConfig | null> => {
    const { currentJson } = JsonEditorInstanceWrapper;
    return ApiManager.api
      .saveJson(currentJson)
      .then((response) => {
        showToast(ToastModes.success, LOCALIZATION.SAVE_SUCCESS);
        return response;
      })
      .catch((error: any) => {
        showToast(
          ToastModes.error,
          Utils.replaceString(
            LOCALIZATION.SAVE_ERROR,
            Utils.extractErrorMessage(error)
          )
        );
        return null;
      });
  };

  public static onUpdate = async (
    selectedJsonConfigId: number | null,
    json?: JsonPayLoad
  ): Promise<JsonConfig | null> => {
    let { currentJson } = JsonEditorInstanceWrapper;

    if (json) {
      currentJson = json;
    }

    if (selectedJsonConfigId) {
      return ApiManager.api
        .updateJson(selectedJsonConfigId, currentJson)
        .then((response: any) => {
          const updatedConfig = response.data.data.items[0];
          showToast(ToastModes.success, LOCALIZATION.UPDATE_SUCCESS);
          return updatedConfig;
        })
        .catch((error: any) => {
          showToast(
            ToastModes.error,
            Utils.replaceString(
              LOCALIZATION.UPDATE_ERROR,
              Utils.extractErrorMessage(error)
            )
          );
          return null;
        });
    }
    ApiManager.metrics.track(MetricsEvents.Error, {
      msg: LOCALIZATION.CANNOT_UPDATE_ID_NULL,
    });
    return null;
  };

  public static onDelete = async (
    selectedJsonConfigId: number | null
  ): Promise<boolean> => {
    if (selectedJsonConfigId) {
      return ApiManager.api
        .deleteJson(selectedJsonConfigId)
        .then(() => {
          showToast(ToastModes.success, LOCALIZATION.DELETE_SUCCESS);
          return true;
        })
        .catch((error: any) => {
          showToast(
            ToastModes.error,
            Utils.replaceString(
              LOCALIZATION.DELETE_ERROR,
              Utils.extractErrorMessage(error)
            )
          );
          return false;
        });
    }
    ApiManager.metrics.track(MetricsEvents.Error, {
      msg: LOCALIZATION.CANNOT_DELETE_ID_NULL,
    });
    return false;
  };
}
