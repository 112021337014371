import React, { PropsWithChildren, useState } from 'react';
import Upload from 'antd/lib/upload';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { FileUploadStatus } from 'userInterface/util/enums/FileUploadStatus';
import { Button } from '@cognite/cogs.js';
import { ToastModes } from 'userInterface/util/enums/ToastModes';
import { LOCALIZATION } from '../../../constants';
import './FileUploader.scss';
import { showToast } from '../showToast/showToast';

export interface FileUploaderPropTypes {
  onUpload: (file?: UploadFile | undefined) => void;
  onRemove: () => void;
}

export const FileUploader: React.FC<FileUploaderPropTypes> = (
  props: PropsWithChildren<FileUploaderPropTypes>
) => {
  const [fileList, setFileList] = useState<UploadFile<any>[] | undefined>(
    undefined
  );

  // This method is added to override default file upload functionality of Upload component
  const customRequest = (prop: any) => {
    setTimeout(() => {
      prop.onSuccess();
    }, 0);
  };

  const settings = {
    name: 'fileUploader',
    customRequest,
    accept: '.yaml',
    fileList,
    maxCount: 1,
    onChange(info: UploadChangeParam<UploadFile<any>>) {
      if (info.file.status === FileUploadStatus.Done) {
        showToast(ToastModes.success, LOCALIZATION.FILE_UPLOAD_OK);
        props.onUpload(info.fileList.slice(-1)[0]);
      } else if (info.file.status === FileUploadStatus.Error) {
        showToast(ToastModes.success, LOCALIZATION.FILE_UPLOAD_FAILED);
      }
      if (info.file.status === FileUploadStatus.Removed) {
        props.onRemove();
        showToast(ToastModes.success, LOCALIZATION.SWITCHED_TO_DEFAULT);
      }
      // Only show latest uploaded file, and old ones will be replaced by the new
      setFileList(info.fileList.slice(-1));
    },
  };

  return (
    <Upload {...settings}>
      <Button
        className="schema-upload-button"
        icon="Upload"
        type="primary"
        size="default"
        variant="ghost"
      >
        {LOCALIZATION.SIDENAV_UPLOAD}
      </Button>
    </Upload>
  );
};
