import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Metrics } from '@cognite/metrics';
import '@cognite/cogs.js/dist/cogs.css';
import config from 'utils/config';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import App from './userInterface/App';
// import App from './App';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // This is populated by the FAS build process. Change it if you want to
    // source this information from somewhere else.
    release: process.env.REACT_APP_RELEASE_ID,
    // This is populated by react-scripts. However, this can be overridden by
    // the app's build process if you wish.
    environment: config.env,
  });
}

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  Metrics.init({
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
    debug: process.env.REACT_APP_MIXPANEL_DEBUG === 'true',
    environment: config.env,
  });
}
console.log('Configurator Version: ', process.env.REACT_APP_VERSION);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
