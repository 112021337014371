import { Avatar, Button, Dropdown, Menu, TopBar } from '@cognite/cogs.js';
import { ApiManager } from 'core/ApiManager';
import { IIdInfo } from 'core/interface/IIdInfo';
import { useSessionOut } from 'customHooks/useSessionOut';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userInfo } from 'redux/appState';
import { CopyButton } from 'userInterface/components/CopyButton/CopyButton';
import { getProjectFromUrl } from 'utils/url';
import './TopBarPanel.scss';

export const TopBarPanel: React.FC = () => {
  const [info, setInfo] = useState<IIdInfo | null>();
  const [, setLoggedOut] = useState(false);
  const [logout] = useSessionOut();
  const { userEmail } = useSelector(userInfo);
  const project = getProjectFromUrl(window.location.href);

  useEffect(() => {
    ApiManager.api.userInfo().then((data: IIdInfo | null) => {
      setInfo(data);
    });
  }, []);

  const invokeLogout = () => {
    logout();
    setLoggedOut(true);
  };

  const switchProject = () => {
    ApiManager.api.switchProject();
  };

  const userMenu = (
    <Menu>
      <Menu.Header>SERVICE ACCOUNT</Menu.Header>
      <Menu.Item>
        <div className="menu-with-icon">
          <span>{userEmail}</span>
          <CopyButton text={userEmail} />
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={invokeLogout}>Logout from your Idp</Menu.Item>
    </Menu>
  );

  const projectMenu = (
    <Menu>
      <Menu.Header>PROJECT ID</Menu.Header>
      <Menu.Item>
        <div className="menu-with-icon">
          <span>{project}</span>
          <CopyButton text={info?.user ?? ''} />
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Header>SWITCH PROJECT</Menu.Header>
      <Menu.Item onClick={switchProject}>
        <span>Select another project</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <TopBar>
      <TopBar.Left>
        <TopBar.Logo
          title="Cognite Remote Configurator"
          subtitle={
            <Dropdown content={projectMenu}>
              <Button icon="Down" iconPlacement="right" unstyled>
                {project}
              </Button>
            </Dropdown>
          }
        />
      </TopBar.Left>
      <TopBar.Navigation links={[]} />
      <TopBar.Right>
        <TopBar.Actions
          actions={[
            {
              key: 'action4',
              component: <Avatar text={userEmail} />,
              menu: userMenu,
            },
          ]}
        />
      </TopBar.Right>
    </TopBar>
  );
};
