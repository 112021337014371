import React from 'react';
import { Modal } from '@cognite/cogs.js';
import { LOCALIZATION } from '../../constants';

interface Props {
  onOk: () => any;
  onCancel: () => any;
}

export const DiscardChangesModal: React.FC<Props> = ({
  onOk,
  onCancel,
}: Props) => (
  <Modal
    title={LOCALIZATION.SWITCH_TITLE}
    visible
    onOk={onOk}
    onCancel={onCancel}
  >
    <h4>{LOCALIZATION.SWITCH_CONTENT}</h4>
  </Modal>
);
