import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from '@cognite/cogs.js';
import { useMetrics } from '@cognite/metrics';
import { useSDK } from '@cognite/sdk-provider';
import { Client } from 'cdf/client';
import classes from './CogniteRemoteConfigurator.module.scss';
import { JsonConfig, JsonPayLoad, MergeOptions } from '../../util/types';
import { CommandEvent } from '../../util/enums/CommandEvent';
import { JsonEditorInstanceWrapper } from '../../../core/JsonEditorInstanceWrapper';
import { CommandPanel } from '../CommandPanel/CommandPanel';
import { SideNavPanel } from '../SideNavPanel/SideNavPanel';
import { JsonEditorContainer } from '../JsonEditorContainer/JsonEditorContainer';
import { DiffMerge } from '../../components/DiffMerge/DiffMerge';
import { MergeModes } from '../../util/enums/MergeModes';
import { LOCALIZATION } from '../../../constants';
import { IOpenApiSchema } from '../../../validator';
import { TabPanel } from '../TabPanel/TabPanel';
import { TopBarPanel } from '../TopBarPanel/TopBarPanel';
import { ApiManager } from '../../../core/ApiManager';
import { Utils } from '../../../core/Utils';
import { MetricsEvents } from '../../util/enums/MetricsEvents';

export const CogniteRemoteConfigurator: React.FC<any> = () => {
  const client = useSDK();
  Client.sdk = client;

  const [jsonConfigMap, setJsonConfigMap] = useState<Map<
    number,
    JsonConfig
  > | null>(null);
  const [selectedJsonConfigId, setSelectedJsonConfigId] = useState<
    number | null
  >(null);
  const [jsonConfig, setJsonConfig] = useState<JsonConfig | null>(null);
  const [originalJsonConfig, setOriginalJsonConfig] =
    useState<JsonConfig | null>(null);
  const [customSchema, setCustomSchema] = useState<IOpenApiSchema | null>(null);

  const [title, setTitle] = useState(LOCALIZATION.UNTITLED);
  const [mode, setMode] = useState('tree');
  const [isEdited, setIsEdited] = useState(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [errorMap, setErrorMap] = useState<Map<string, string[]>>(new Map());

  const [showMerge, setShowMerge] = useState<boolean>(false);
  const compareJsons = useRef<{
    originalConfig: string;
    editedConfig: string;
  }>();
  const handleOkMerge = useRef<any>(() => {
    metrics.track(MetricsEvents.MergeSuccess, {
      id: selectedJsonConfigId,
      mergedJsons: compareJsons.current,
      msg: LOCALIZATION.SUCCESSFUL_MERGE,
    });
  });
  const handleCancelMerge = useRef<any>(() => null);
  const diffMode = useRef<MergeModes>();

  const metrics = useMetrics('CogniteRemoteConfigurator');

  const loadJsonConfigs = async () => {
    const jsonConfigs = await ApiManager.loadJsonConfigs();
    setJsonConfigMap(jsonConfigs);
  };

  useEffect(() => {
    loadJsonConfigs();
    metrics.track(MetricsEvents.Login);
  }, []);

  const setMergeOptions = (options: MergeOptions) => {
    compareJsons.current = {
      originalConfig: options.originalConfig,
      editedConfig: options.editedConfig,
    };
    diffMode.current = options.diffMode;
    handleOkMerge.current = options.onOk;
    handleCancelMerge.current = options.onCancel;
    setShowMerge(true);
  };

  const onCommand = async (
    command: CommandEvent,
    ...args: any[]
  ): Promise<void> => {
    const loadJsonConfigsAndReturnLatest = async (
      id: number | null
    ): Promise<[Map<number, JsonConfig>, JsonConfig | null]> => {
      const jsonConfigs = await ApiManager.loadJsonConfigs();

      let selectedJsonConfig = null;
      if (id !== null && jsonConfigs && jsonConfigs.size > 0) {
        selectedJsonConfig = jsonConfigs.get(id);
      }
      return [jsonConfigs, selectedJsonConfig];
    };

    switch (command) {
      case CommandEvent.mode: {
        setMode(args[0]);
        JsonEditorInstanceWrapper.onModeChange(args[0]);

        metrics.track(MetricsEvents.ModeChange, { mode });
        break;
      }
      case CommandEvent.switchConfig: {
        const configId = args[0];
        setSelectedJsonConfigId(configId);
        if (configId === null) {
          const configName = args[1];
          // new config
          const newConfig = { header: { name: configName } } as JsonPayLoad;
          setJsonConfig({
            data: newConfig,
            id: null,
          } as JsonConfig);
          JsonEditorInstanceWrapper.setEditorText(newConfig);
          setOriginalJsonConfig(null);

          metrics.track(MetricsEvents.NewConfig, {
            name: newConfig.header.name,
            mode,
          });
        } else {
          let selectedJsonConfig;
          if (jsonConfigMap && jsonConfigMap.size > 0) {
            selectedJsonConfig = jsonConfigMap.get(configId);
          }
          if (selectedJsonConfig) {
            JsonEditorInstanceWrapper.setEditorText(selectedJsonConfig?.data);
            setJsonConfig(selectedJsonConfig);
            setOriginalJsonConfig(selectedJsonConfig);

            metrics.track(MetricsEvents.SwitchConfig, {
              name: selectedJsonConfig?.data?.header?.name,
              id: configId,
              mode,
            });
          } else {
            metrics.track(MetricsEvents.SwitchConfigErr, {
              id: configId,
              mode,
              msg: LOCALIZATION.SELECTED_CONFIG_NOT_FOUND_IN_LIST,
            });
          }
        }
        break;
      }
      case CommandEvent.saveAs: {
        const mergeResult = args[0]; // local changes if server version was deleted in the server on save
        if (mergeResult) {
          //
          JsonEditorInstanceWrapper.updateEditorText(mergeResult);
        }
        const newJson = await ApiManager.onSaveAs();
        if (newJson) {
          const configId = newJson.id;
          const [jsonConfigs, latestConfig] =
            await loadJsonConfigsAndReturnLatest(configId);

          setJsonConfigMap(jsonConfigs);
          setSelectedJsonConfigId(configId);

          if (latestConfig) {
            if (!Utils.isEqualConfigs(latestConfig, jsonConfig)) {
              JsonEditorInstanceWrapper.updateEditorText(latestConfig?.data);
            }
            setJsonConfig(latestConfig);
            setOriginalJsonConfig(latestConfig);
          }
        }
        break;
      }
      case CommandEvent.update: {
        const currentJson = args[0];
        const updatedConfig = await ApiManager.onUpdate(
          selectedJsonConfigId,
          currentJson
        );
        if (updatedConfig) {
          const configId = updatedConfig.id;
          const [jsonConfigs, latestConfig] =
            await loadJsonConfigsAndReturnLatest(configId);

          setJsonConfigMap(jsonConfigs);
          setSelectedJsonConfigId(configId);

          if (latestConfig) {
            if (!Utils.isEqualConfigs(latestConfig, jsonConfig)) {
              JsonEditorInstanceWrapper.updateEditorText(latestConfig?.data);
            }
            setJsonConfig(latestConfig);
            setOriginalJsonConfig(latestConfig);
          } else {
            metrics.track(MetricsEvents.UpdateError, {
              id: selectedJsonConfigId,
              mode,
              msg: LOCALIZATION.SELECTED_CONFIG_NOT_FOUND_IN_LIST,
            });
          }
        }
        break;
      }
      case CommandEvent.delete: {
        const status = await ApiManager.onDelete(selectedJsonConfigId);
        if (status) {
          const jsonConfigs = await ApiManager.loadJsonConfigs();
          setJsonConfigMap(jsonConfigs);
          setSelectedJsonConfigId(null);

          // switch to blank
          const newConfig = {} as JsonPayLoad;
          setJsonConfig({
            data: newConfig,
            id: null,
          } as JsonConfig);
          JsonEditorInstanceWrapper.setEditorText(newConfig);
          setOriginalJsonConfig(null);
        }
        break;
      }
      case CommandEvent.download: {
        JsonEditorInstanceWrapper.onDownload();
        break;
      }
      case CommandEvent.diff: {
        const mergedConfig = args[0];
        const newConfigJson = { id: selectedJsonConfigId, data: mergedConfig };
        if (!Utils.isEqualConfigs(newConfigJson, jsonConfig)) {
          JsonEditorInstanceWrapper.updateEditorText(mergedConfig);
          setJsonConfig(newConfigJson);
        }
        break;
      }
      case CommandEvent.loadSchema: {
        setCustomSchema(args[0] || null);
        break;
      }
      case CommandEvent.reload: {
        const currentJson = args[0] || jsonConfig?.data; // local changes if server version was deleted in the server on save

        const [jsonConfigs, latestConfig] =
          await loadJsonConfigsAndReturnLatest(selectedJsonConfigId);

        setJsonConfigMap(jsonConfigs);

        if (latestConfig) {
          JsonEditorInstanceWrapper.updateEditorText(currentJson);
          setJsonConfig({ id: selectedJsonConfigId, data: currentJson });

          if (!isEdited) {
            JsonEditorInstanceWrapper.setEditorText(latestConfig?.data);
            setJsonConfig(latestConfig);
          }
          setOriginalJsonConfig(latestConfig);
        } else {
          // if currently selected config is deleted
          if (!isEdited) {
            // if view not edited reset
            const newConfig = {} as JsonPayLoad;
            setJsonConfig({
              data: newConfig,
              id: null,
            } as JsonConfig);
            JsonEditorInstanceWrapper.setEditorText(newConfig);
          } else {
            JsonEditorInstanceWrapper.updateEditorText(currentJson);
          }

          setSelectedJsonConfigId(null);
          setOriginalJsonConfig(null);
        }

        setRefreshing(false);
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    const eventListener = (e: any) => {
      if (isEdited) {
        (e || window.event).returnValue = true; // Gecko + IE
        return true; // Gecko + Webkit, Safari, Chrome etc.
      }
      return false;
    };

    window.addEventListener('beforeunload', eventListener);

    return () => {
      window.removeEventListener('beforeunload', eventListener);
    };
  });

  // set title

  useEffect(() => {
    const checkEditStatus = (
      currentConfig: JsonConfig | null,
      originalConfig: JsonConfig | null
    ) => {
      if (currentConfig) {
        if (originalConfig === null) {
          return !!Object.keys(currentConfig?.data).length;
        }
        return !Utils.isEqualConfigs(originalConfig, currentConfig);
      }
      return false;
    };

    const updateTitle = (
      currentConfig: JsonConfig | null,
      originalConfig: JsonConfig | null
    ): void => {
      const editStatus = checkEditStatus(currentConfig, originalConfig);

      if (JsonEditorInstanceWrapper.editor) {
        const currentTitle =
          (editStatus ? '*' : '') +
          (JsonEditorInstanceWrapper.currentFileName || LOCALIZATION.UNTITLED);
        const currentMode = JsonEditorInstanceWrapper.editor?.getMode();

        setTitle(currentTitle);
        setMode(currentMode);
      }
      setIsEdited(editStatus);
    };

    updateTitle(jsonConfig, originalJsonConfig);
  }, [jsonConfig, originalJsonConfig, jsonConfigMap]);

  const onUpdateJson = (json: JsonPayLoad) => {
    setJsonConfig({
      id: selectedJsonConfigId || null,
      data: json,
    });
  };

  const onEditorError = (errors: Map<string, string[]>) => {
    setErrorMap(errors);
  };

  return (
    <>
      <ToastContainer />
      <div className={classes.configurator}>
        <div className={classes.header}>
          <TopBarPanel />
        </div>

        <div className={classes.menu}>
          <SideNavPanel
            isEdited={isEdited}
            commandEvent={onCommand}
            jsonConfigMap={jsonConfigMap}
            selectedJsonConfigId={selectedJsonConfigId}
          />
        </div>

        <div className={classes.editor}>
          <CommandPanel
            title={title}
            mode={mode}
            refreshing={refreshing}
            setRefreshing={setRefreshing}
            commandEvent={onCommand}
            isEdited={isEdited}
            setMergeOptions={setMergeOptions}
            selectedJsonConfigId={selectedJsonConfigId}
            originalJsonConfig={originalJsonConfig}
            hasErrors={errorMap.size > 0}
          />
          <div className={classes.TabEditorContainer}>
            <TabPanel commandEvent={onCommand} />
            <JsonEditorContainer
              onUpdateJson={onUpdateJson}
              customSchema={customSchema}
              onError={onEditorError}
            />
          </div>
        </div>
      </div>
      <div>
        {diffMode.current && showMerge && (
          <DiffMerge
            setShowMerge={setShowMerge}
            showPopup={showMerge}
            originalConfig={compareJsons.current?.originalConfig}
            editedConfig={compareJsons.current?.editedConfig}
            onMerge={handleOkMerge.current}
            onCancel={handleCancelMerge.current}
            diffMode={diffMode.current}
          />
        )}
      </div>
    </>
  );
};
