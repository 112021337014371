import React, { useState } from 'react';
import { Icon, Title } from '@cognite/cogs.js';
import * as yaml from 'js-yaml';
import { DiscardChangesModal } from 'userInterface/modals/DiscardChangesModal';
import { AddNewConfigModal } from 'userInterface/modals/AddNewConfigModal';
import classes from './SideNavPanel.module.scss';
import { CommandEvent } from '../../util/enums/CommandEvent';
import { ConfigSelector } from '../../components/ConfigSelector/ConfigSelector';
import { FileUploader } from '../../components/FileUploader/FileUploader';
import { LOCALIZATION } from '../../../constants';
import { JsonEditorInstanceWrapper } from '../../../core/JsonEditorInstanceWrapper';

export const SideNavPanel: React.FC<{
  isEdited: boolean;
  commandEvent: (commandEvent: CommandEvent, ...args: any[]) => void;
  jsonConfigMap: Map<number, unknown> | null;
  selectedJsonConfigId: number | null;
}> = (props: any) => {
  const [modifiedBeforeCreateNew, setModifiedBeforeCreateNew] = useState(false);
  const [newConfigIdForSwtiching, setNewConfigIdForSwitching] = useState(0);

  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [configName, setConfigName] = useState('');

  const handleCreateNew = () => {
    if (props.isEdited) {
      setModifiedBeforeCreateNew(true);
    } else {
      showCreateNew();
    }
  };

  const showCreateNew = () => {
    setShowCreatePopup(true);
    setModifiedBeforeCreateNew(false);
  };

  const onCreateNewConfig = () => {
    setShowCreatePopup(false);
    props.commandEvent(CommandEvent.switchConfig, null, configName);
  };

  const handleSwitchConfig = (id: number) => {
    if (props.isEdited) {
      setNewConfigIdForSwitching(id);
    } else {
      props.commandEvent(CommandEvent.switchConfig, id);
    }
  };

  const switchConfig = () => {
    props.commandEvent(CommandEvent.switchConfig, newConfigIdForSwtiching);
    setNewConfigIdForSwitching(0);
  };

  const onUpload = (evt: any) => {
    const reader = new FileReader();
    const file = evt.originFileObj;
    reader.onload = () => {
      try {
        const yamlObj = yaml.load(reader.result as string);
        props.commandEvent(CommandEvent.loadSchema, yamlObj);
      } catch (error) {
        JsonEditorInstanceWrapper.schemaErrors.push(
          LOCALIZATION.INVALID_SCHEMA
        );
      }
    };
    reader.readAsText(file);
  };

  const onRemoveUploadedSchema = () => {
    props.commandEvent(CommandEvent.loadSchema);
  };

  return (
    <>
      {/* Display the DiscardChanges modal if CreateNew is pressed with local changes */}
      {modifiedBeforeCreateNew && (
        <DiscardChangesModal
          onOk={showCreateNew}
          onCancel={() => setModifiedBeforeCreateNew(false)}
        />
      )}

      {/* Display the DiscardChanges modal if different config is selected with local changes */}
      {newConfigIdForSwtiching !== 0 && (
        <DiscardChangesModal
          onOk={switchConfig}
          onCancel={() => setNewConfigIdForSwitching(0)}
        />
      )}

      {/* Display the CreateNewConfig modal */}
      {showCreatePopup && (
        <AddNewConfigModal
          onOk={onCreateNewConfig}
          onCancel={() => setShowCreatePopup(false)}
          onChange={(e) => setConfigName(e)}
        />
      )}
      <div className={classes.container}>
        <div className={classes.head}>
          <Title level={4} as="p" className={classes.twins}>
            {LOCALIZATION.SIDENAV_SUBTITLE}
          </Title>
          <div
            className={classes.createNewBtn}
            role="button"
            tabIndex={0}
            onKeyDown={handleCreateNew}
            onClick={handleCreateNew}
          >
            <div className={classes.icon}>
              <Icon type="PlusCompact" />
            </div>
            <div className={classes.span}>
              <Title level={6} as="span" color="blue">
                {LOCALIZATION.SIDENAV_CREATENEW}
              </Title>
            </div>
          </div>
        </div>

        <div className={classes.jsonConfigContainer}>
          <div className={classes.jsonConfigs}>
            <ConfigSelector
              jsonConfigMap={props.jsonConfigMap}
              selectedJsonConfigId={props.selectedJsonConfigId}
              onSelectItem={handleSwitchConfig}
            />
          </div>
        </div>

        <div className={classes.uploadButton}>
          <div className={classes.grid}>
            <div className={classes.btn}>
              <FileUploader
                onUpload={onUpload}
                onRemove={onRemoveUploadedSchema}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
