import { getFlow } from '@cognite/auth-utils';
import { getProjectFromUrl } from 'utils/url';

const AUTH_RESULT_STORAGE_KEY = 'authResult';
const KEY_LAST_TENANT = 'last_CDF_project';

export const useSessionOut = () => {
  const project = getProjectFromUrl(window.location.href);

  const logout = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const env = urlParams.get('cluster') || '';

    const { flow } = getFlow(project, env);

    if (flow === 'AZURE_AD') {
      window.location.replace(window.location.origin);
    } else {
      legacyLogout();
    }
  };

  const legacyLogout = async () => {
    /**
     * exactly what is happening on react container
     */
    localStorage.removeItem(AUTH_RESULT_STORAGE_KEY);
    localStorage.removeItem(KEY_LAST_TENANT);
    // saveFlow('UNKNOWN');
    window.location.replace(window.location.origin);
  };

  return [logout];
};
