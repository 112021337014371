import React from 'react';
import { Dropdown, Menu, Popconfirm, Title } from '@cognite/cogs.js';
import { ToastModes } from 'userInterface/util/enums/ToastModes';
import { showToast } from 'userInterface/components/showToast/showToast';

import { CommandItem } from '../../components/CommandItem/CommandItem';
import { CommandEvent } from '../../util/enums/CommandEvent';
import { JsonEditorInstanceWrapper } from '../../../core/JsonEditorInstanceWrapper';
import { ApiManager } from '../../../core/ApiManager';
import { LOCALIZATION } from '../../../constants';
import { JsonConfig, MergeOptions } from '../../util/types';
import { MergeModes } from '../../util/enums/MergeModes';
import classes from './CommandPanel.module.scss';
import { Utils } from '../../../core/Utils';

export async function getLatestConfiguration(
  configurationId: number | null
): Promise<JsonConfig | null> {
  return ApiManager.loadJsonConfigs()
    .then((response) => {
      if (response) {
        const selectedJsonConfig: JsonConfig = response.get(configurationId);
        if (selectedJsonConfig) {
          return selectedJsonConfig;
        }
        return null;
      }
      return null;
    })
    .catch((error) => {
      showToast(
        ToastModes.error,
        Utils.replaceString(
          LOCALIZATION.RETRIEVE_CONFIGS_FAIL,
          Utils.extractErrorMessage(error)
        )
      );
      return null;
    });
}

export interface CommandPanelProps {
  title: string;
  mode: string;
  isEdited: boolean;
  refreshing: boolean;
  hasErrors: boolean;
  selectedJsonConfigId: number | null;
  originalJsonConfig: JsonConfig | null;
  commandEvent: (commandEvent: CommandEvent, ...args: any[]) => void;
  setMergeOptions: (options: MergeOptions) => void;
  setRefreshing: (refreshing: boolean) => void;
}

export const CommandPanel: React.FC<CommandPanelProps> = ({
  commandEvent,
  selectedJsonConfigId,
  setMergeOptions,
  isEdited,
  setRefreshing,
  originalJsonConfig,
  title,
  refreshing,
  hasErrors,
}: CommandPanelProps) => {
  const isUpdated = async (): Promise<boolean> => {
    if (selectedJsonConfigId) {
      const latestJsonConfig = await getLatestConfiguration(
        selectedJsonConfigId
      );
      return (
        JSON.stringify(originalJsonConfig) !== JSON.stringify(latestJsonConfig)
      );
    }
    return false;
  };

  const onReloadHandler = async () => {
    setRefreshing(true);
    if (await isUpdated()) {
      if (isEdited) {
        const latestJsonConfig = await getLatestConfiguration(
          selectedJsonConfigId
        );
        setMergeOptions({
          editedConfig: JsonEditorInstanceWrapper.currentJson,
          originalConfig: latestJsonConfig?.data,
          diffMode: latestJsonConfig
            ? MergeModes.reload
            : MergeModes.reloadServerDeleted,
          onOk: (mergedJson: any) => {
            commandEvent(CommandEvent.reload, mergedJson);
            showToast(ToastModes.success, LOCALIZATION.REFRESH_SUCCESS);
          },
          onCancel: () => {
            showToast(
              ToastModes.warning,
              Utils.replaceString(LOCALIZATION.REFRESH_ERROR, '')
            );
            setRefreshing(false);
          },
        });
      } else {
        commandEvent(CommandEvent.reload);
      }
    } else {
      // reload with current text
      commandEvent(CommandEvent.reload);
    }
  };

  const save = async () => {
    if (await isUpdated()) {
      const latestJsonConfig = await getLatestConfiguration(
        selectedJsonConfigId
      );
      setMergeOptions({
        editedConfig: JsonEditorInstanceWrapper.currentJson,
        originalConfig: latestJsonConfig?.data,
        diffMode: latestJsonConfig
          ? MergeModes.save
          : MergeModes.saveServerDeleted,
        onOk: (mergedJson: any) => {
          if (latestJsonConfig) {
            commandEvent(CommandEvent.update, mergedJson);
          } else {
            // if file is deleted on the server save as new
            commandEvent(CommandEvent.saveAs, mergedJson);
          }
        },
        onCancel: () => {
          showToast(
            ToastModes.warning,
            Utils.replaceString(LOCALIZATION.UPDATE_ERROR, '')
          );
        },
      });
    } else {
      commandEvent(CommandEvent.update);
    }
  };

  const onDownloadHandler = () => {
    commandEvent(CommandEvent.download);
  };

  const onDiffHandler = () => {
    setMergeOptions({
      // updated version
      editedConfig: JsonEditorInstanceWrapper.currentJson,
      // Latest update
      originalConfig: originalJsonConfig?.data,
      diffMode: MergeModes.diff,
      onOk: (mergedJson: any) => {
        commandEvent(CommandEvent.diff, mergedJson);
        showToast(ToastModes.success, LOCALIZATION.DIFF_SUCCESS);
      },
      onCancel() {
        showToast(ToastModes.success, LOCALIZATION.DIFF_CANCEL);
      },
    });
  };

  const DeleteButton = () => (
    <Popconfirm
      icon="WarningFilled"
      placement="bottom-end"
      onConfirm={() => {
        commandEvent(CommandEvent.delete);
      }}
      onCancel={() =>
        showToast(
          ToastModes.warning,
          Utils.replaceString(LOCALIZATION.DELETE_ERROR, '')
        )
      }
      content={LOCALIZATION.DELETE_TITLE}
    >
      <CommandItem
        tooltip="Delete Configuration"
        icon="Delete"
        disabled={!selectedJsonConfigId}
      />
    </Popconfirm>
  );

  const getSaveOptionsContent = (saveOption?: true) => {
    if (!JsonEditorInstanceWrapper.currentFileName) {
      return saveOption
        ? LOCALIZATION.UPDATE_WITHOUT_NAME_CONTENT
        : LOCALIZATION.SAVE_WITHOUT_NAME_CONTENT;
    }
    if (hasErrors) {
      return saveOption
        ? LOCALIZATION.UPDATE_WITH_ERRORS_CONTENT
        : LOCALIZATION.SAVE_WITH_ERRORS_CONTENT;
    }
    return saveOption ? LOCALIZATION.UPDATE_CONTENT : LOCALIZATION.SAVE_CONTENT;
  };

  const SaveMenuItem = () => (
    <Popconfirm
      icon="Info"
      placement="bottom-end"
      onConfirm={() => save()}
      onCancel={() =>
        showToast(
          ToastModes.warning,
          Utils.replaceString(LOCALIZATION.UPDATE_ERROR, '')
        )
      }
      content={getSaveOptionsContent(true)}
    >
      <Menu.Item disabled={!selectedJsonConfigId || !isEdited}>Save</Menu.Item>
    </Popconfirm>
  );

  const SaveAsMenuItem = () => (
    <Popconfirm
      icon="Info"
      placement="bottom-end"
      onConfirm={() => commandEvent(CommandEvent.saveAs)}
      onCancel={() =>
        showToast(
          ToastModes.warning,
          Utils.replaceString(LOCALIZATION.SAVE_ERROR, '')
        )
      }
      content={getSaveOptionsContent()}
    >
      <Menu.Item>Save As New</Menu.Item>
    </Popconfirm>
  );

  const saveMenu = (
    <Menu>
      <SaveMenuItem />
      <SaveAsMenuItem />
    </Menu>
  );

  return (
    <div className={classes.commandsContainer}>
      <div className={classes.errorPanel}>
        {JsonEditorInstanceWrapper.schemaErrors.map((error) => (
          <div className={classes.errorItem}>
            <span> {error}</span>
          </div>
        ))}
      </div>

      <div className={classes.titlePanel}>
        <Title level={3} as="p">
          {title}
        </Title>
      </div>
      <div className={classes.rightPanel}>
        {/* Diff */}
        <CommandItem
          tooltip="Diff view"
          onClick={onDiffHandler}
          icon="Duplicate"
          disabled={!isEdited}
        />

        {/* Refresh */}
        <CommandItem
          tooltip="Reload Configurations"
          onClick={onReloadHandler}
          icon="Refresh"
          loading={refreshing}
        />

        {/* Download */}
        <CommandItem
          tooltip="Download Configuration"
          onClick={onDownloadHandler}
          icon="Download"
        />

        {/* Delete */}
        <DeleteButton />

        {/* Save Menu */}
        <Dropdown content={saveMenu}>
          <CommandItem
            type="primary"
            tooltip="Save"
            icon="Down"
            iconPlacement="right"
          >
            Save
          </CommandItem>
        </Dropdown>
      </div>
    </div>
  );
};
