import { useEffect, useRef } from 'react';
import './JsonEditorContainer.scss';
import { JsonEditorInstanceWrapper } from '../../../core/JsonEditorInstanceWrapper';
import { JsonPayLoad } from '../../util/types';
import { IOpenApiSchema } from '../../../validator';

export function JsonEditorContainer(props: {
  onUpdateJson: (text: JsonPayLoad) => void;
  customSchema: IOpenApiSchema | null;
  onError: (errorMap: Map<string, string[]>) => void;
}): JSX.Element {
  const jsonEditorElm = useRef<HTMLDivElement | null>(null);
  const { onUpdateJson, customSchema, onError } = props;
  const updateCallBack = useRef(onUpdateJson);
  const onErrorCallBack = useRef(onError);

  useEffect(() => {
    // onupdatejson function will be updated on each rerender of parent therefore we need to store it in a ref so
    // JsonEditor can call the correct function after instantiation
    updateCallBack.current = onUpdateJson;
  }, [onUpdateJson]);

  useEffect(() => {
    // update error callback
    onErrorCallBack.current = onError;
  }, [onError]);

  useEffect(() => {
    const onChange = () => {
      const { editor } = JsonEditorInstanceWrapper;
      if (editor) {
        updateCallBack.current(editor.get() as JsonPayLoad);
      }
    };

    const onEditorError = (errors: Map<string, string[]>) => {
      const { editor } = JsonEditorInstanceWrapper;
      if (editor) {
        onErrorCallBack.current(errors);
      }
    };

    (async () => {
      if (jsonEditorElm.current !== null) {
        const currentConfig = JsonEditorInstanceWrapper.currentJson;
        await JsonEditorInstanceWrapper.onLoadSchema(
          jsonEditorElm.current,
          onChange,
          onEditorError,
          customSchema,
          currentConfig
        );
      }
    })();
  }, [jsonEditorElm.current, customSchema]);

  return <div className="json-editor-container" ref={jsonEditorElm} />;
}
