import { ValidationError } from 'jsoneditor';
import { IOpenApiSchema } from './interfaces/IOpenApiSchema';
import { ITemplateNode } from './interfaces/ITemplateNode';
import { BaseNode } from './nodes/BaseNode';
import { IValidationResult } from './interfaces/IValidationResult';

export abstract class CogniteOpenApiSchemaValidator {
  private compiled = false;
  public errors: { path: string; message: string }[] = [];

  public abstract compile(schema?: IOpenApiSchema): Promise<boolean>;

  public get ready(): boolean {
    return this.compiled;
  }

  public set ready(status: boolean) {
    this.compiled = status;
  }

  public abstract schemaNodes(): ITemplateNode[];

  public abstract schemaNode(
    path: (string | number)[],
    json: any
  ): BaseNode | null;

  public abstract schemaNodeWithError(
    path: (string | number)[],
    json: any
  ): IValidationResult;

  public abstract removeJsonNode(
    json: any,
    path: (string | number)[]
  ): IValidationResult;

  abstract validate(json: any): ValidationError[];
}
