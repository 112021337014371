import { useEffect, useState } from 'react';
import { getFlow } from '@cognite/auth-utils';
import { useSDK } from '@cognite/sdk-provider';
import { getProjectFromUrl } from 'utils/url';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { updateUserInfo } from 'redux/appState';
import * as Sentry from '@sentry/browser';
import { getSidecarForCluster, Sidecar } from '../utils/sidecar';
import { useCluster } from './useCluster';
import { IAuthData } from '../interfaces/interfaces';

export const useAuthState = (): [
  IAuthData,
  Sidecar,
  (isAuthed: boolean) => void
] => {
  const [authData, setAuthData] = useState<IAuthData>({} as IAuthData);

  const sdk = useSDK();
  const dispatch = useDispatch();
  const [cluster] = useCluster();
  const project = getProjectFromUrl(window.location.href);
  const { flow, options } = getFlow(project, cluster); // fix for getting proper login
  const sidecar: Sidecar = getSidecarForCluster(cluster);

  const azureAuth = async (isAuthed: boolean) => {
    try {
      let isAuthenticated = isAuthed;
      if (!isAuthed) {
        await sdk.loginWithOAuth({
          type: 'AAD_OAUTH',
          options: {
            cluster: sidecar.cdfCluster === '' ? 'api' : sidecar.cdfCluster,
            clientId: sidecar.aadApplicationId,
            tenantId: options?.directory,
            signInType: {
              type: 'loginRedirect',
            },
          },
        });
        isAuthenticated = await sdk.authenticate();
      }

      const cdfToken = await sdk.getCDFToken();
      const idToken = await sdk.getIdToken();

      if (idToken) {
        const { name, email } =
          jwtDecode<{ name: string; email: string }>(idToken);
        if (!email) {
          const scope = new Sentry.Scope();
          scope.setContext('info', { name, project });
          Sentry.captureMessage(`email is undefined for user`, scope);
        }
        dispatch(updateUserInfo({ email: email || '', name }));
      }

      const authObj: IAuthData = {
        access_token: cdfToken || '',
        id_token: idToken ?? '',
        isAuthenticated,
        project,
      };
      setAuthData({ ...authObj });
    } catch (error) {
      // console.log('Error - useAuthState', error);
    }
  };

  const cogniteAuth = async (isAuthed: boolean) => {
    try {
      let isAuthenticated = isAuthed;
      if (!isAuthed) {
        if (cluster) {
          sdk.setBaseUrl(`https://${cluster}.cognitedata.com`);
        }
        await sdk.loginWithOAuth({
          type: 'CDF_OAUTH',
          options: {
            project,
          },
        });
        isAuthenticated = await sdk.authenticate();
      }
      if (isAuthenticated) {
        const cdfToken = await sdk.getCDFToken();
        const authObj = {
          access_token: cdfToken || '',
          id_token: '',
          isAuthenticated,
          project,
        };
        setAuthData(authObj);
      }
    } catch (error) {
      // console.log('Error - useAuthState', error);
    }
  };

  const auth = (isAuthed: boolean) => {
    if (flow === 'AZURE_AD') {
      azureAuth(isAuthed);
    } else {
      cogniteAuth(isAuthed);
    }
  };

  useEffect(() => {
    auth(false);
  }, []);

  return [authData, sidecar, auth];
};
