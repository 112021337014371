import { ValidationError } from 'jsoneditor';
import isPlainObject from 'lodash-es/isPlainObject';
import isArray from 'lodash-es/isArray';
import isNumber from 'lodash-es/isNumber';
import isBoolean from 'lodash-es/isBoolean';
import isString from 'lodash-es/isString';
import { Metrics } from '@cognite/metrics';
import { CogniteOpenApiSchemaValidator } from './CogniteOpenApiSchemaValidator';
import { SchemaResolver } from './SchemaResolver';
import { ITemplateNode } from './interfaces/ITemplateNode';
import { BaseNode } from './nodes/BaseNode';
import { IValidationResult } from './interfaces/IValidationResult';
import { DataType } from './enum/DataType';
import { LOCALIZATION } from '../constants';
import { Utils } from '../core/Utils';
import { AssociationType } from './enum/AssociationType';
import { MetricsEvents } from '../userInterface/util/enums/MetricsEvents';

export class CogniteOpenApiSchemaValidatorImpl extends CogniteOpenApiSchemaValidator {
  private metrics = Metrics.create('CogniteOpenApiSchemaValidatorImpl');

  public async compile(schema: any): Promise<boolean> {
    this.ready = await SchemaResolver.parseYAMLFile(schema);
    return this.ready;
  }

  public schemaNodes(): ITemplateNode[] {
    return SchemaResolver.getAllNodes();
  }

  public schemaNode(path: (string | number)[], json: any): BaseNode | null {
    return SchemaResolver.getNodeMeta(path, json)?.resultNode || null;
  }

  public schemaNodeWithError(
    path: (string | number)[],
    json: any
  ): IValidationResult {
    return SchemaResolver.getNodeMeta(path, json);
  }

  public removeJsonNode(
    json: any,
    path: (string | number)[]
  ): IValidationResult {
    return SchemaResolver.removeNode(json, path);
  }

  public validate(json: any): ValidationError[] {
    const schemaMeta = SchemaResolver.getNodeMeta([], json).resultNode;
    return this.validateFields(json, schemaMeta);
  }

  /**
   * Validates if
   * 1. All required keys from schema are available
   * 2. there are no invalid keys
   * 3. Discriminate properties are available and follows the schema
   * @param json
   * @param schemaMeta
   * @param discriminator
   * @param paths
   * @param errors
   * @private
   */
  private validateFields(
    json: any,
    schemaMeta: any,
    paths: any[] = [],
    errors: ValidationError[] = []
  ): ValidationError[] {
    const missingRequiredFields = [];
    const validatedKeys = new Set<string>();
    const schemaType = schemaMeta.type;
    let schemaMetaData: any;
    const { discriminator } = schemaMeta;
    const { nullable } = schemaMeta;
    let validationErrors = errors;

    if (schemaType === DataType.any) {
      return validationErrors;
    }

    // check nullable
    if (!nullable && json === null) {
      validationErrors.push({
        path: paths,
        message: LOCALIZATION.VAL_CANNOT_BE_NULL,
      });

      return validationErrors;
    }

    if (discriminator) {
      if (schemaType === DataType.object) {
        schemaMetaData = schemaMeta.data;
      } else if (schemaType === DataType.array || schemaType === DataType.map) {
        schemaMetaData = schemaMeta.sampleData;
      }
      const discriminatorErrors = this.validateDiscriminator(
        json,
        schemaMetaData,
        discriminator,
        paths
      );
      validationErrors = discriminatorErrors.concat(validationErrors);
    } else {
      switch (schemaType) {
        case DataType.object: {
          schemaMetaData = schemaMeta.data;

          if (!isPlainObject(json)) {
            validationErrors.push({
              path: paths,
              message: LOCALIZATION.VAL_NOT_OBJECT,
            });
            return validationErrors;
          }

          for (const childKey of Object.keys(schemaMetaData)) {
            const childValue = json[childKey];
            const childPath = paths.concat([childKey]);
            const childMeta = schemaMetaData[childKey];

            const isChildKeyRequired = childMeta.isRequired;

            const hasKey = Object.prototype.hasOwnProperty.call(json, childKey);

            if (!hasKey) {
              if (isChildKeyRequired) {
                missingRequiredFields.push(childKey);
              }
            } else {
              validatedKeys.add(childKey);

              const childErrors = this.validateFields(
                childValue,
                childMeta,
                childPath
              );
              validationErrors = childErrors.concat(validationErrors);
            }
          }

          for (const jsonChildKey of Object.keys(json)) {
            // validate unnecessary fields
            if (!validatedKeys.has(jsonChildKey)) {
              const newPath = paths.concat([jsonChildKey]);
              validationErrors.push({
                path: newPath,
                message: Utils.replaceString(
                  LOCALIZATION.NOT_VALID_KEY,
                  jsonChildKey
                ),
              });
            }
          }
          break;
        }
        case DataType.map:
        case DataType.array: {
          if (schemaType === DataType.array) {
            if (!isArray(json)) {
              validationErrors.push({
                path: paths,
                message: LOCALIZATION.VAL_NOT_ARR,
              });
              return validationErrors;
            }

            const arrayValidationErrors = this.validateArray(
              json,
              schemaMeta,
              paths
            );
            validationErrors = arrayValidationErrors.concat(validationErrors);
          }

          if (schemaType === DataType.map) {
            if (!isPlainObject(json)) {
              validationErrors.push({
                path: paths,
                message: LOCALIZATION.VAL_NOT_OBJECT,
              });
              return validationErrors;
            }
            const { maxProperties } = schemaMeta;
            const { minProperties } = schemaMeta;
            const noOfProperties = Object.keys(json).length;

            if (isNumber(maxProperties) && noOfProperties > maxProperties) {
              validationErrors.push({
                path: paths,
                message: Utils.replaceString(
                  LOCALIZATION.INVALID_MAX_NO_KEY_PAIRS,
                  maxProperties.toString()
                ),
              });
            }

            if (isNumber(minProperties) && noOfProperties < minProperties) {
              validationErrors.push({
                path: paths,
                message: Utils.replaceString(
                  LOCALIZATION.INVALID_MIN_NO_KEY_PAIRS,
                  minProperties.toString()
                ),
              });
            }
          }

          schemaMetaData = schemaMeta.sampleData;

          const callNextIteration = (childKey: any, childValue: any) => {
            const childPath = paths.concat([childKey]);

            const childErrors = this.validateFields(
              childValue,
              schemaMetaData,
              childPath
            );
            validationErrors = childErrors.concat(validationErrors);
          };

          if (schemaType === DataType.map) {
            for (const mapChildKey of Object.keys(json)) {
              const mapChild = json[mapChildKey];
              callNextIteration(mapChildKey, mapChild);
            }
          } else {
            for (let i = 0; i < json.length; i++) {
              const mapChild = json[i];
              callNextIteration(i, mapChild);
            }
          }

          break;
        }
        default: {
          schemaMetaData = schemaMeta;
          const valueErrors = this.validateValues(json, schemaMetaData, paths);
          validationErrors = valueErrors.concat(validationErrors);
        }
      }
    }

    if (missingRequiredFields.length >= 1) {
      if (missingRequiredFields.length === 1) {
        validationErrors.push({
          path: paths,
          message: Utils.replaceString(
            LOCALIZATION.REQUIRED_FIELD_NOT_AVAIL,
            missingRequiredFields[0]
          ),
        });
      } else {
        validationErrors.push({
          path: paths,
          message: Utils.replaceString(
            LOCALIZATION.REQUIRED_FIELDS_NOT_AVAIL,
            missingRequiredFields.join(',')
          ),
        });
      }
    }

    return validationErrors;
  }

  private validateArray(
    json: any,
    schema: any,
    paths: any,
    errors: ValidationError[] = []
  ) {
    if (schema.type === DataType.array) {
      const maxItems = Number(schema.maxItems);
      const minItems = Number(schema.minItems);

      const elementCount = json.length;
      if (!Number.isNaN(maxItems)) {
        if (maxItems >= 0) {
          if (elementCount > maxItems) {
            errors.push({
              path: paths,
              message: Utils.replaceString(
                LOCALIZATION.MAX_ARR_ELEMENTS_EXCEEDED,
                maxItems.toString()
              ),
            });
          }
        } else {
          this.metrics.track(MetricsEvents.ValidatorError, {
            msg: Utils.replaceString(
              LOCALIZATION.VALIDATOR_INVALID_MAX_ELEMENT_CONFIG,
              paths.join('.')
            ),
            json,
            schema,
            paths,
          });
        }
      }
      if (!Number.isNaN(minItems)) {
        if (minItems >= 0) {
          if (elementCount < minItems) {
            errors.push({
              path: paths,
              message: Utils.replaceString(
                LOCALIZATION.MIN_ARR_ELEMENTS_NOT_FOUND,
                minItems.toString()
              ),
            });
          }
        } else {
          this.metrics.track(MetricsEvents.ValidatorError, {
            msg: Utils.replaceString(
              LOCALIZATION.VALIDATOR_INVALID_MIN_ELEMENT_CONFIG,
              paths.join('.')
            ),
            json,
            schema,
            paths,
          });
        }
      }

      const shouldBeUnique = schema.uniqueItems;

      // uniqueness validation

      if (shouldBeUnique) {
        const uniqueSet = new Set();
        json.forEach((item: any, index: number) => {
          if (uniqueSet.has(item)) {
            const itemPath = paths.concat([index]);
            errors.push({
              path: itemPath,
              message: Utils.replaceString(
                LOCALIZATION.ARR_ELEMENT_VIOLATES_UNIQUENESS,
                item.toString()
              ),
            });
          } else {
            uniqueSet.add(item);
          }
        });
      }
    } else {
      this.metrics.track(MetricsEvents.ValidatorError, {
        msg: Utils.replaceString(
          LOCALIZATION.VALIDATOR_INVALID_ARRAY_VALIDATION,
          schema.type
        ),
        json,
        schema,
        paths,
      });
    }
    return errors;
  }

  private validateDiscriminator(
    json: any,
    schema: any,
    discriminator: { propertyName: string },
    paths: any[],
    errors: ValidationError[] = []
  ): ValidationError[] {
    const discriminatorType = json[discriminator.propertyName];
    let validationErrors = errors;

    if (discriminatorType) {
      // whether discriminator property is available
      const discriminatorMeta = schema[discriminatorType];
      if (discriminatorMeta) {
        const childErrors = this.validateFields(json, discriminatorMeta, paths);
        validationErrors = childErrors.concat(validationErrors);
      } else {
        validationErrors.push({
          path: paths,
          message: Utils.replaceString(
            LOCALIZATION.DISCRIM_INVALID_TYPE,
            discriminator.propertyName
          ),
        });
      }
    } else {
      validationErrors.push({
        path: paths,
        message: Utils.replaceString(
          LOCALIZATION.REQUIRED_FIELD_NOT_AVAIL,
          discriminator.propertyName
        ),
      });
    }
    return validationErrors;
  }

  private validateValues(
    value: string | boolean | number | null | undefined,
    schema: any,
    paths: any[],
    errors: ValidationError[] = []
  ): ValidationError[] {
    if (schema) {
      const datatype: DataType = schema.type;
      const { possibleValues } = schema;
      const { isRequired } = schema;
      const associationType = schema.association;

      if (possibleValues && possibleValues.length) {
        let isOneOfPossibleValues = false;
        for (const possibleVal of possibleValues) {
          if (value === possibleVal) {
            isOneOfPossibleValues = true;
          }
        }
        if (!isOneOfPossibleValues) {
          errors.push({
            path: paths,
            message: LOCALIZATION.VAL_NOT_OF_POSSIBLE_VALS,
          });
        }
      }

      if (!value && value !== 0) {
        if (isRequired) {
          errors.push({ path: paths, message: LOCALIZATION.VAL_NOT_BE_EMPTY });
        }
      } else {
        switch (datatype) {
          case DataType.number: {
            const { minimum } = schema;
            const { maximum } = schema;

            if (associationType === AssociationType.NOT) {
              if (!Number.isNaN(Number(value))) {
                errors.push({
                  path: paths,
                  message: LOCALIZATION.VAL_CANNOT_BE_NUMBER,
                });
              }
            } else if (Number.isNaN(Number(value))) {
              errors.push({
                path: paths,
                message: LOCALIZATION.VAL_NOT_NUMBER,
              });
            } else {
              if (minimum) {
                if (value < minimum) {
                  errors.push({
                    path: paths,
                    message: Utils.replaceString(
                      LOCALIZATION.VAL_CANNOT_BE_LESS,
                      minimum
                    ),
                  });
                }
              }
              if (maximum) {
                if (value > maximum) {
                  errors.push({
                    path: paths,
                    message: Utils.replaceString(
                      LOCALIZATION.VAL_CANNOT_BE_GREATER,
                      maximum
                    ),
                  });
                }
              }
            }
            break;
          }
          case DataType.boolean: {
            if (associationType === AssociationType.NOT) {
              if (isBoolean(value)) {
                errors.push({
                  path: paths,
                  message: LOCALIZATION.VAL_CANNOT_BE_BOOLEAN,
                });
              }
            } else if (!isBoolean(value)) {
              errors.push({
                path: paths,
                message: LOCALIZATION.VAL_NOT_BOOLEAN,
              });
            }
            break;
          }
          case DataType.string: {
            if (associationType === AssociationType.NOT) {
              if (isString(value)) {
                errors.push({
                  path: paths,
                  message: LOCALIZATION.VAL_CANNOT_BE_STRING,
                });
              }
            } else if (!isString(value)) {
              errors.push({
                path: paths,
                message: LOCALIZATION.VAL_NOT_STRING,
              });
            } else {
              const maxLength = Number(schema.maxLength);
              const { length } = value;
              if (maxLength && length > maxLength) {
                errors.push({
                  path: paths,
                  message: Utils.replaceString(
                    LOCALIZATION.STRING_LENGTH_EXCEEDED,
                    maxLength.toString()
                  ),
                });
              }

              const { pattern } = schema;

              if (pattern) {
                const regex = new RegExp(pattern);
                const matches = regex.test(value);

                if (!matches) {
                  errors.push({
                    path: paths,
                    message: Utils.replaceString(
                      LOCALIZATION.STRING_VIOLATES_PATTERN,
                      pattern
                    ),
                  });
                }
              }
            }
            break;
          }
        }
      }
    }
    return errors;
  }
}
