import { JsonEditorInstanceWrapper } from '../core/JsonEditorInstanceWrapper';
import { ISchemaNode } from './interfaces/ISchemaNode';

export class SchemaValidator {
  public static validateUnresolvedSchema(unresolvedSchema: ISchemaNode) {
    for (const val of Object.values(unresolvedSchema)) {
      const schemaNode = val as ISchemaNode;

      if (schemaNode.properties) {
        this.checkProperties(schemaNode);
      }

      if (schemaNode.allOf) {
        for (const node of schemaNode.allOf) {
          this.checkProperties(node);
        }
      }

      if (schemaNode.oneOf) {
        for (const node of schemaNode.oneOf) {
          this.checkProperties(node);
        }
      }
    }
  }

  private static checkProperties(schemaNode: ISchemaNode) {
    if (schemaNode.properties) {
      for (const val of Object.values(schemaNode.properties)) {
        if (val.properties) {
          JsonEditorInstanceWrapper.schemaErrors.push(
            `Nested types should comes with a ref: ${val.description}`
          );
        }
        if (val.additionalProperties && !val.additionalProperties.$ref) {
          JsonEditorInstanceWrapper.schemaErrors.push(
            `additionalProperties should have a ref: ${val.description}`
          );
        }
        if (val.items && val.items.properties) {
          JsonEditorInstanceWrapper.schemaErrors.push(
            `items of an object array should have a ref: ${val.description}`
          );
        }
      }
    }
  }
}
