import { Router } from 'react-router-dom';
import React from 'react';
import * as Sentry from '@sentry/react';
import { CogniteClient } from '@cognite/sdk';
import { createBrowserHistory } from 'history';
import './App.scss';
import { SDKProvider } from '@cognite/sdk-provider';
import { Loader } from '@cognite/cogs.js';
import Routes from 'routes';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'redux/store';
import GlobalStyles from 'global-styles';

const App = () => {
  const Client = new CogniteClient({
    appId: 'cognite-remote-configurator',
  });
  const history = createBrowserHistory();
  return (
    <Sentry.ErrorBoundary
      fallback={
        <>
          <h4>An error has occured</h4>
          <p>Please try reloading the app.</p>
        </>
      }
    >
      <GlobalStyles />
      <React.Suspense fallback={<Loader />}>
        <ReduxProvider store={store}>
          <SDKProvider sdk={Client}>
            <Router history={history}>
              <Routes />
            </Router>
          </SDKProvider>
        </ReduxProvider>
      </React.Suspense>
    </Sentry.ErrorBoundary>
  );
};
export default App;
