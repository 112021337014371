import { useEffect, useRef } from 'react';
import { Button, Modal } from '@cognite/cogs.js';
import AceDiff, { AceDiffConstructorOpts } from 'ace-diff';
import { useMetrics } from '@cognite/metrics';
import classes from './DiffMerge.module.scss';
import { LOCALIZATION, MergeText } from '../../../constants';
import { MergeModes } from '../../util/enums/MergeModes';
import { MetricsEvents } from '../../util/enums/MetricsEvents';
import { ToastModes } from '../../util/enums/ToastModes';
import { showToast } from '../showToast/showToast';

export function DiffMerge(props: {
  setShowMerge: (state: boolean) => void;
  showPopup: boolean;
  originalConfig: any;
  editedConfig: any;
  diffMode: MergeModes;
  onMerge: (mergedJsonString: string) => void;
  onCancel: () => void;
}) {
  const metrics = useMetrics('DiffMerge');
  const originalConfig = JSON.stringify(props.originalConfig, null, 2);
  const editedConfig = JSON.stringify(props.editedConfig, null, 2);
  const differInstance = useRef<AceDiff | null>(null);
  const originalDeleted = !originalConfig;
  const mergeText = MergeText[props.diffMode];

  useEffect(() => {
    if (props.showPopup) {
      setTimeout(() => {
        differInstance.current = new AceDiff(
          getAceDiffOptions(editedConfig, props.diffMode, originalConfig)
        );
      }, 350);
    }
  }, [props.showPopup]);

  const handleCancelMerge = () => {
    props.onCancel();
    props.setShowMerge(false);
  };

  const handleMerge = (mergeText: string) => {
    let mergedJson;
    try {
      mergedJson = JSON.parse(mergeText);
      props.onMerge(mergedJson);
      props.setShowMerge(false);
    } catch (e: any) {
      showToast(ToastModes.error, LOCALIZATION.MERGE_SYNTAX_ERROR);

      metrics.track(MetricsEvents.MergeError, {
        msg: LOCALIZATION.MERGE_PARSING_ERROR,
        jsonString: mergeText,
      });
    }
  };

  const handleLeftMerge = () => {
    const differ = differInstance.current;
    if (differ) {
      const mergedJsonString = differ.getEditors().left.getValue();
      handleMerge(mergedJsonString);
    }
  };

  const handleRightMerge = () => {
    const differ = differInstance.current;
    if (differ) {
      const mergedJsonString = differ.getEditors().right.getValue();
      handleMerge(mergedJsonString);
    }
  };

  return (
    <Modal
      title="Merge Versions"
      visible={props.showPopup}
      onCancel={handleCancelMerge}
      width={1050}
      footer={[
        <div className={classes.footer}>
          <div>
            <Button
              className={classes.diffviewButton}
              key="left"
              onClick={handleLeftMerge}
              hidden={originalDeleted}
            >
              {mergeText.btnLeft}
            </Button>
            <Button
              className={classes.diffviewButton}
              key="your"
              onClick={handleRightMerge}
              type="primary"
              variant="default"
            >
              {mergeText.btnRight}
            </Button>
          </div>
        </div>,
      ]}
    >
      <div className={classes.editorLblContainer}>
        <span className="editor-lbl">{mergeText.txtLeft}</span>
        <span className="editor-lbl">{mergeText.txtRight}</span>
      </div>
      <div
        className={`${classes.mergePrompt} acediff ${
          originalDeleted && 'original-deleted'
        } ${props.diffMode === MergeModes.diff && 'diff'}`}
      />
    </Modal>
  );
}

export function getAceDiffOptions(
  currentJson: string,
  mode: MergeModes,
  serverJson?: string
): AceDiffConstructorOpts {
  let options: AceDiffConstructorOpts;

  if (!serverJson) {
    options = {
      element: '.acediff',
      showDiffs: false,
      showConnectors: false,
      diffGranularity: 'specific',
      left: {
        content:
          mode === MergeModes.diff
            ? LOCALIZATION.NEW_CONFIG
            : LOCALIZATION.FILE_DELETED_IN_SERVER,
        copyLinkEnabled: false,
        editable: false,
      },
      right: {
        content: currentJson,
        copyLinkEnabled: false,
      },
    };
  } else {
    options = {
      element: '.acediff',
      diffGranularity: 'specific',
      left: {
        content: serverJson,
      },
      right: {
        content: currentJson,
      },
    };
  }
  return options;
}
