export enum MetricsEvents {
  Login = 'LoginSuccess',
  ModeChange = 'ModeChanged',
  NewConfig = 'NewConfig',
  SwitchConfig = 'SwitchConfig',
  SwitchConfigErr = 'SwitchConfigError',
  UpdateError = 'UpdateError',
  Error = 'Error',
  Warn = 'Warning',
  Info = 'Info',
  SuccessRetrieveList = 'SuccessRetrieveConfigurationsList',
  SaveSuccess = 'SaveSuccess',
  MergeError = 'MergeError',
  MergeSuccess = 'MergeSuccess',
  ValidatorError = 'ValidatorError',
  SchemaError = 'SchemaError',
}
