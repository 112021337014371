import TenantSelector, { Background } from '@cognite/cdf-hub-tenant-selector';
import { getEnvironment, getSidecarForCluster } from '../../utils/sidecar';
import { Environment } from '../../enums/environment';
import { clusters } from './Clusters';
import useEnv from '../../customHooks/useEnv';

export default function LoginView() {
  const [env, setEnv] = useEnv();
  const sidecar = getSidecarForCluster(env);

  return (
    <Background>
      <TenantSelector
        appName="Cognite Remote"
        clientId={sidecar.aadApplicationId}
        clusters={clusters}
        cluster={env}
        setCluster={setEnv}
        move={(project: string) => {
          window.location.href = `/${project}${env ? `?cluster=${env}` : ''}`;
        }}
        isProduction={getEnvironment() === Environment.PRODUCTION}
      />
    </Background>
  );
}
