import JSONEditor from 'jsoneditor';
import { CogniteJsonEditorOptions } from './CogniteJsonEditorOptions';
import { CogniteOpenApiSchemaValidator } from '../validator';

export class CogniteJsonEditor extends JSONEditor {
  private optionsObj: CogniteJsonEditorOptions;

  constructor(
    elm: HTMLElement,
    onChange: (text: string) => void,
    onError: (errorMap: Map<string, string[]>) => void,
    validator: CogniteOpenApiSchemaValidator,
    json?: any
  ) {
    const opts = new CogniteJsonEditorOptions(validator, onChange, onError);
    super(elm, opts, json);
    this.optionsObj = opts;
  }

  public get options(): CogniteJsonEditorOptions {
    return this.optionsObj;
  }

  public set options(opts: CogniteJsonEditorOptions) {
    this.optionsObj = opts;
  }

  public get codeModeEditor(): any {
    // @ts-ignore
    return this.aceEditor;
  }
}
