/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IAppState } from '../interfaces/interfaces';

const appState: IAppState = {
  appError: null,
  authData: null,
  loginType: null,
  loginInputs: null,
  azureTenant: null,
  isLoggedOut: false,
  userEmail: '',
  userName: '',
};

const appStateSlice = createSlice({
  name: 'AppState',
  initialState: appState,
  reducers: {
    updateUserInfo(
      state: IAppState,
      action: PayloadAction<{ name: string; email: string }>
    ) {
      state.userEmail = action.payload.email;
      state.userName = action.payload.name;
    },
  },
});

export const { updateUserInfo } = appStateSlice.actions;
export const userInfo = ({ userEmail, userName }: IAppState) => ({
  userEmail,
  userName,
});
export default appStateSlice.reducer;
