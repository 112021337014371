import SwaggerParser from '@apidevtools/swagger-parser';
import { Metrics } from '@cognite/metrics';
import { ISchemaNode } from './interfaces/ISchemaNode';
import { BaseNode } from './nodes/BaseNode';
import { getJson, getNodeInstance } from './util/Helper';
import { DataType } from './enum/DataType';
import { MapNode } from './nodes/MapNode';
import { ArrayNode } from './nodes/ArrayNode';
import { JsonEditorInstanceWrapper } from '../core/JsonEditorInstanceWrapper';
import { SchemaValidator } from './SchemaValidator';
import { ITemplateNode } from './interfaces/ITemplateNode';
import { NodeFactory } from './util/NodeFactory';
import { IOpenApiSchema } from './interfaces/IOpenApiSchema';
import { IValidationResult } from './interfaces/IValidationResult';
import { ErrorType } from './enum/ErrorType';
import { ID_SEPRATOR } from '../constants';
import { MetricsEvents } from '../userInterface/util/enums/MetricsEvents';

const defaultGroup = 'TwinConfiguration';

export class SchemaResolver {
  public static idSeparator = ID_SEPRATOR;
  private static allNodes: ITemplateNode[] = [];
  private static rootDataNode: { [key: string]: BaseNode } = {};
  private static metrics = Metrics.create('SchemaResolver');

  public static getNodeMeta(
    paths: (string | number)[],
    rootJson: any,
    group: string = defaultGroup
  ): IValidationResult {
    return getNodeInstance(group, this.rootDataNode, rootJson, paths);
  }

  public static getRootDataNode() {
    return this.rootDataNode;
  }

  public static getAllNodes(): ITemplateNode[] {
    return this.allNodes;
  }

  public static removeNode(
    data: Record<string, unknown>,
    paths: (string | number)[],
    group: string = defaultGroup
  ): IValidationResult {
    const root = { ...this.rootDataNode };

    const result = getNodeInstance(group, root, data, paths);
    const resultParent =
      paths.length > 1
        ? getNodeInstance(group, root, data, paths.slice(0, paths.length - 1))
        : null;

    if (!result.error) {
      if (result.resultNode?.isRequired) {
        return {
          group,
          error: {
            type: ErrorType.RequiredNode,
          },
        };
      }
      if (
        resultParent &&
        resultParent.resultNode instanceof ArrayNode &&
        resultParent.resultNode.minItems
      ) {
        let subTree = data;
        paths.slice(0, paths.length - 1).forEach((step: number | string) => {
          subTree = subTree[step] as Record<string, unknown>;
        });

        if (
          (subTree as unknown as any[]).length <=
          resultParent.resultNode.minItems
        ) {
          return {
            group,
            error: {
              type: ErrorType.MinLength,
            },
          };
        }
      }

      return {
        group,
        resultNode: null,
      };
    }
    return result;
  }

  private static getSample(node: BaseNode) {
    if (node instanceof MapNode || node instanceof ArrayNode) {
      const sample = node.sampleData;
      const js = getJson(sample);
      return js;
    }
    return null;
  }

  public static parseYAMLFile(ymlJson: IOpenApiSchema): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Initialize static data before switch the schema
      this.rootDataNode = {};
      this.allNodes = [];

      // propUniqueIdentifier is a counter which is used to make the description is unique for all schemaTypes.
      // Otherwise schema types cannot be identified uniquely from allTemplates array
      let propUniqueIdentifier = 0;

      const nodeFactory = new NodeFactory();

      const unresolvedSchema = ymlJson.components.schemas;
      SchemaValidator.validateUnresolvedSchema(unresolvedSchema);

      SwaggerParser.validate(ymlJson, (err, api) => {
        if (api) {
          const rootSchema = api.components.schemas;

          // Assign a unique identifier for all the property descriptions
          for (const val of Object.values(rootSchema)) {
            const schemaNode = val as ISchemaNode;
            schemaNode.description = `${schemaNode.description}${
              this.idSeparator
            }${(propUniqueIdentifier += 1)}`;

            if (schemaNode.properties) {
              for (const c of Object.values(schemaNode.properties)) {
                c.description = `${c.description}${
                  this.idSeparator
                }${(propUniqueIdentifier += 1)}`;
              }
            }
          }

          for (const [key, val] of Object.entries(rootSchema)) {
            const childrenNodes = nodeFactory.populateChildren(
              val as ISchemaNode,
              true
            );
            this.rootDataNode[key] = childrenNodes;
          }

          BaseNode.rootNode = this.rootDataNode;

          // Populate root nodes
          for (const [key1, group] of Object.entries(this.rootDataNode)) {
            if (group.data) {
              for (const [key2, val2] of Object.entries(group.data)) {
                if (group.type === DataType.object) {
                  this.allNodes.push({
                    key: `${key1}:${key2}`,
                    node: val2,
                    data: getJson(group, true)[key2],
                    sample: SchemaResolver.getSample((group.data as any)[key2]), // Used only for Array, Map
                  });
                }
              }
            }
          }
          this.metrics.track(MetricsEvents.Info, {
            msg: 'Schema YML',
            schema: rootSchema,
          });
          // console.log("Schema Node", this.rootDataNode);
          resolve(true);
        } else {
          JsonEditorInstanceWrapper.schemaErrors.push(
            'Configuration Schema has errors! Validations may not work as expected'
          );

          this.metrics.track(MetricsEvents.SchemaError, {
            error: err,
          });
          reject();
        }
      });
    });
  }
}
