import React, { PropsWithChildren } from 'react';
import { Body, Title } from '@cognite/cogs.js';
import classes from './ConfigSelector.module.scss';
import { JsonConfig } from '../../util/types';

interface JsonConfigWithName {
  id: number;
  name: string;
}

export interface ConfigSelectorPropTypes {
  jsonConfigMap: Map<number, JsonConfig> | null;
  selectedJsonConfigId: number | null;
  onSelectItem: (id: number) => any;
}

export const ConfigSelector: React.FC<ConfigSelectorPropTypes> = ({
  selectedJsonConfigId,
  jsonConfigMap,
  onSelectItem,
}: PropsWithChildren<ConfigSelectorPropTypes>) => {
  const jsonConfigs: JsonConfigWithName[] = [];

  jsonConfigMap?.forEach((element: JsonConfig) => {
    jsonConfigs.push({
      id: element.id!,
      name: element.data?.header?.name,
    });
  });

  const ConfigItem = (itemObj: any) => {
    const config = itemObj.item;

    return (
      <>
        <div
          className={`${classes.jsonConfigItem}  ${
            config.id === selectedJsonConfigId ? classes.selected : ''
          }`}
          role="button"
          tabIndex={0}
          onKeyDown={() => onSelectItem(config.id)}
          onClick={() => onSelectItem(config.id)}
        >
          <div>
            <Title level={6} as="span">
              {config.name}
            </Title>
          </div>
          <div>
            <Body level={3} as="span">
              {config.id}
            </Body>
          </div>
        </div>
      </>
    );
  };

  if (jsonConfigs.length) {
    return (
      <div className={classes.container}>
        {jsonConfigs.map((item) => (
          <ConfigItem item={item} key={item.id} />
        ))}
      </div>
    );
  }
  return null;
};
