import React, { ChangeEvent, useState } from 'react';
import { Input, Tabs } from '@cognite/cogs.js';
import { CommandEvent } from 'userInterface/util/enums/CommandEvent';
import { Modes } from 'userInterface/util/enums/Modes';
import { JsonEditorInstanceWrapper } from '../../../core/JsonEditorInstanceWrapper';
import './TabPanel.scss';

export const TabPanel: React.FC<{
  commandEvent: (commandEvent: CommandEvent, ...args: any[]) => void;
}> = (props: {
  commandEvent: (commandEvent: CommandEvent, ...args: any[]) => void;
}) => {
  const [searchVal, setSearchVal] = useState('');
  const onModeSwitch = (mode: string) => {
    if (searchVal) {
      setSearchVal('');
    }
    switch (mode) {
      case Modes.default:
        props.commandEvent(CommandEvent.mode, Modes.default);
        break;
      case Modes.paste:
        props.commandEvent(CommandEvent.mode, Modes.paste);
        break;

      default:
        break;
    }
  };

  const handleSearch = (evt: ChangeEvent) => {
    const { value } = evt.target as HTMLInputElement;
    setSearchVal(value);
    JsonEditorInstanceWrapper.searchText(value);
  };

  return (
    <div className="TabsInputContainer">
      <div className="TabsContainer">
        <Tabs
          defaultActiveKey="home"
          onChange={onModeSwitch}
          size="large"
          padding="default"
          style={{ backgroundColor: 'inherit' }}
        >
          <Tabs.TabPane key={Modes.default} tab={<span>Tree View</span>} />
          <Tabs.TabPane key={Modes.paste} tab={<span>Code View</span>} />
        </Tabs>
      </div>
      <div className="SearchContainer">
        <Input
          variant="default"
          placeholder="search"
          icon="Search"
          iconPlacement="left"
          clearable={{
            labelText: 'Clear text',
            callback: () => {
              setSearchVal('');
              JsonEditorInstanceWrapper.searchText('');
            },
          }}
          onChange={handleSearch}
          value={searchVal}
          fullWidth
        />
      </div>
    </div>
  );
};
