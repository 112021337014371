import { Switch, Route, useRouteMatch } from 'react-router-dom';
import LoginView from 'pages/Login/Login';
import { CogniteRemoteConfigurator } from 'userInterface/panels/CogniteRemoteConfigurator/CogniteRemoteConfigurator';
import { getProjectFromUrl } from 'utils/url';
import { useAuthState } from './customHooks/useAuthState';

const AppRoutes = () => {
  const { path } = useRouteMatch();
  const [authData] = useAuthState();

  if (authData?.access_token) {
    return (
      <Switch>
        <Route path={`${path}/`} exact>
          <CogniteRemoteConfigurator />
        </Route>
      </Switch>
    );
  }
  return null;
};

const Routes = () => {
  const project = getProjectFromUrl(window.location.href);

  return (
    <Switch>
      <Route exact path="/" component={LoginView} />
      <Route path={`/${project}`}>
        <AppRoutes />
      </Route>
    </Switch>
  );
};

export default Routes;
