import isEqual from 'lodash-es/isEqual';
import { JsonConfig } from '../userInterface/util/types';
import { STRING_PLACEHOLDER } from '../constants';

export class Utils {
  public static extractErrorMessage = (error: string): string => {
    const errorMsg = `${error}`.split(' | ')[0].split(': ')[1];
    return errorMsg;
  };

  public static isEqualConfigs = (
    configA: JsonConfig | null,
    configB: JsonConfig | null
  ) => isEqual(configA?.data, configB?.data);

  public static replaceString = (str: string, replacement: string): string => {
    if (str) {
      return str.replace(STRING_PLACEHOLDER, replacement);
    }
    return '';
  };
}
