import React, { PropsWithChildren } from 'react';
import { Button, Tooltip } from '@cognite/cogs.js';

export interface CommandItemPropTypes {
  type?: 'link' | 'danger' | 'primary' | 'secondary';
  tooltip?: string;
  onClick?: () => void;
  icon?: any;
  disabled?: boolean;
  iconPlacement?: 'left' | 'right' | undefined;
  loading?: boolean;
}

export const CommandItem: React.FC<CommandItemPropTypes> = ({
  type = 'secondary',
  iconPlacement = 'left',
  disabled = false,
  tooltip,
  onClick,
  icon,
  loading,
  children,
}: PropsWithChildren<CommandItemPropTypes>) => (
  <div>
    <Tooltip content={<span>{tooltip}</span>}>
      <Button
        type={type}
        size="small"
        onClick={onClick}
        disabled={disabled || false}
        icon={icon}
        iconPlacement={iconPlacement}
        loading={loading}
      >
        {children}
      </Button>
    </Tooltip>
  </div>
);
