import { HttpRequestOptions, HttpResponse, CogniteClient } from '@cognite/sdk';
import { Metrics } from '@cognite/metrics';
import { getProjectFromUrl } from 'utils/url';
import { Client } from '../cdf/client';
import { Api } from './Api';
import { MetricsEvents } from '../userInterface/util/enums/MetricsEvents';
import { LOCALIZATION } from '../constants';

export class DigitalTwinApi implements Api {
  private client: CogniteClient;
  private metrics = Metrics.create('DigitalTwinApi');

  constructor() {
    this.client = Client.sdk;
  }

  public get apiUrl(): string {
    const project = getProjectFromUrl(window.location.href);
    return `${this.client.getBaseUrl()}/api/playground/projects/${project}`;
  }

  public jsonList = async (): Promise<any> =>
    this.client.get(`${this.apiUrl}/twins`).then((response) => {
      this.metrics.track(MetricsEvents.SuccessRetrieveList, {
        msg: LOCALIZATION.SUCCESSFUL_CONFIG_LIST_RETRIEVAL,
      });
      const jsonConfigs = response.data.data?.items;
      const jsonConfigIdMap = new Map();

      for (const jsonConfig of jsonConfigs) {
        const jsonConfigId = jsonConfig.id;
        jsonConfigIdMap.set(jsonConfigId, jsonConfig);
      }
      return jsonConfigIdMap;
    });

  public saveJson = async (json: any): Promise<HttpResponse<any>> => {
    const options: HttpRequestOptions = {
      data: {
        items: [{ data: json }],
      },
    };

    return this.client
      .post(`${this.apiUrl}/twins`, options)
      .then((response) => {
        const payload = response.data.data.items[0];

        this.metrics.track(MetricsEvents.SaveSuccess, {
          msg: LOCALIZATION.SUCCESSFUL_SAVE,
          id: payload.id,
        });
        return payload;
      });
  };

  public updateJson = async (
    configId: number,
    json: any
  ): Promise<HttpResponse<any>> => {
    const options: HttpRequestOptions = {
      data: {
        items: [
          {
            id: configId,
            data: json,
          },
        ],
      },
    };

    return this.client.post(`${this.apiUrl}/twins/update`, options);
  };

  public deleteJson = async (configId: number): Promise<HttpResponse<any>> => {
    const options: HttpRequestOptions = {
      data: {
        items: [configId],
      },
    };

    return this.client.post(`${this.apiUrl}/twins/delete`, options);
  };

  public logout = async () => {
    const url = await this.client.logout.getUrl();
    if (url) {
      window.location.href = url;
    }
  };

  public switchProject = async () => {
    window.location.href = window.location.origin;
  };

  public userInfo = async () => this.client.login.status();
}
