import { toast } from '@cognite/cogs.js';
import { ToastModes } from 'userInterface/util/enums/ToastModes';

export const showToast = (
  toastMode: string,
  message: string,
  title?: string
) => {
  let varToast;
  switch (toastMode) {
    case ToastModes.success:
      varToast = toast.success;
      break;
    case ToastModes.error:
      varToast = toast.error;
      break;
    case ToastModes.warning:
      varToast = toast.warning;
      break;
    default:
      varToast = toast.info;
      break;
  }

  varToast(
    <div>
      <h3>{title}</h3>
      {message}
    </div>
  );
};
