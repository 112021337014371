export function sanitizeTenant(tenant: string = '') {
  return tenant.toLowerCase().replace(/[^a-z0-9-]/g, '');
}

export function getProject(path: string = '') {
  return sanitizeTenant(path.match(/^\/([^/]*)(.*)$/)?.[1]);
}

export function getProjectFromUrl(href: string = '') {
  const arr = href.split('?')[0].split('/');
  if (arr.length > 3) {
    return arr[3];
  }
  return '';
}
