import * as YAML from 'js-yaml';
import { saveAs } from 'file-saver';
import { Metrics } from '@cognite/metrics';
import { Modes } from '../userInterface/util/enums/Modes';
import { CogniteJsonEditor } from './CogniteJsonEditor';
import { JsonPayLoad } from '../userInterface/util/types';
import ymlFile from '../config/twinconfig.yaml';
import {
  CogniteOpenApiSchemaValidatorImpl,
  IOpenApiSchema,
} from '../validator';
import { MetricsEvents } from '../userInterface/util/enums/MetricsEvents';
import { LOCALIZATION } from '../constants';

export class JsonEditorInstanceWrapper {
  public static schemaErrors: string[] = [];
  private static editorInstance: CogniteJsonEditor;
  private static metrics = Metrics.create('JsonEditorInstanceWrapper');

  public static async createEditor(
    htmlElement: HTMLElement,
    schema: IOpenApiSchema,
    onChange: (text: string) => void,
    onError: (errorMap: Map<string, string[]>) => void,
    json?: any
  ): Promise<void> {
    const elm = htmlElement;
    // Schema errors need to reset before loading new schema
    this.schemaErrors = [];

    // To force reload the content, innerHTML needs to be changed
    elm.innerHTML = '';

    const validator = new CogniteOpenApiSchemaValidatorImpl();
    await validator.compile(schema);

    JsonEditorInstanceWrapper.editorInstance = new CogniteJsonEditor(
      elm,
      onChange,
      onError,
      validator,
      json
    );
  }

  public static get editor(): CogniteJsonEditor | null {
    if (JsonEditorInstanceWrapper.editorInstance) {
      return JsonEditorInstanceWrapper.editorInstance;
    }
    this.metrics.track(MetricsEvents.Warn, {
      msg: LOCALIZATION.EDITOR_NOT_INSTANTIATED,
    });
    return null;
  }

  public static get currentJson(): any {
    const currentJsonText = JsonEditorInstanceWrapper.editor?.getText();
    let currentJson;
    if (currentJsonText) {
      try {
        currentJson = JSON.parse(currentJsonText);
      } catch (e: any) {
        this.metrics.track(MetricsEvents.Error, {
          msg: LOCALIZATION.JSON_PARSE_ERROR,
          json: currentJsonText,
        });
      }
    }
    return currentJson;
  }

  public static get currentFileName(): string {
    const { currentJson } = JsonEditorInstanceWrapper;
    return currentJson?.header?.name;
  }

  public static setEditorText(json: JsonPayLoad | null) {
    const { editor } = JsonEditorInstanceWrapper;
    if (editor) {
      editor.set(json);
    }
  }

  public static updateEditorText(json: JsonPayLoad | null) {
    const { editor } = JsonEditorInstanceWrapper;
    if (editor) {
      editor.update(json);
    }
  }

  public static onModeChange(mode: Modes): void {
    if (JsonEditorInstanceWrapper.editor) {
      JsonEditorInstanceWrapper.editor.setMode(mode);
    }
  }

  public static onDownload = (): void => {
    const currentJson = JSON.stringify(JsonEditorInstanceWrapper.currentJson);
    let fileName = JsonEditorInstanceWrapper.currentFileName;
    if (!fileName || fileName === '') {
      fileName = 'Untitled Json Config';
    }
    const blob = new Blob([currentJson], {
      type: 'application/json;charset=utf-8',
    });
    saveAs(blob, fileName);
  };

  public static onLoadSchema = async (
    elm: HTMLElement | null,
    onChange: (text: string) => void,
    onError: (errorMap: Map<string, string[]>) => void,
    schema: IOpenApiSchema | null,
    json?: any
  ): Promise<void> => {
    if (elm) {
      if (schema) {
        await JsonEditorInstanceWrapper.createEditor(
          elm,
          schema,
          onChange,
          onError,
          json
        );
      } else {
        const configFile = await fetch(ymlFile).then((response) =>
          response.text()
        );
        const parsedYamlSchema = YAML.load(configFile) as IOpenApiSchema;
        await JsonEditorInstanceWrapper.createEditor(
          elm,
          parsedYamlSchema,
          onChange,
          onError,
          json
        );
      }
    }
  };

  public static searchText(value: any) {
    if (JsonEditorInstanceWrapper.editor) {
      const mode = JsonEditorInstanceWrapper.editor.getMode();

      if (mode === Modes.default) {
        const searchBox = document.querySelector('.jsoneditor-search');
        const input = searchBox?.querySelector('input');

        if (input) {
          input.value = value;
          input.dispatchEvent(new Event('change', { bubbles: true }));
        }
      } else {
        const aceEditor = JsonEditorInstanceWrapper.editor.codeModeEditor;
        aceEditor.findAll(value);
      }
    }
  }
}
